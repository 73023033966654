import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {InventoryWrapper} from '../pages/inventory/InventoryPage'
import {SalesLedgerWrapper} from '../pages/sales-ledger/SalesLedgerPage'
import {ReleaseNotes} from '../pages/release-notes/ReleaseNotesPage'
import {Roadmap} from '../pages/roadmap/RoadmapPage'
import {TermsOfUse} from '../pages/TermsOfUsePage'
import {PrivacyPolicy} from '../pages/PrivacyPolicyPage'
import {AffiliateDisclosures} from '../pages/AffiliateDisclosuresPage'
import {CatalogSetsWrapper} from '../pages/catalog/CatalogSetsPage'
import {CatalogMinifiguresWrapper} from '../pages/catalog/CatalogMinifiguresPage'
import {CatalogPartsWrapper} from '../pages/catalog/CatalogPartsPage'
import {CatalogColorsWrapper} from '../pages/catalog/CatalogColorsPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='inventory-management' element={<InventoryWrapper />} />
        <Route path='sales-ledger' element={<SalesLedgerWrapper />} />
        <Route path='release-notes' element={<ReleaseNotes />} />
        <Route path='roadmap' element={<Roadmap />} />
        <Route path='terms-of-use' element={<TermsOfUse />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='affiliate-disclosures' element={<AffiliateDisclosures />} />
        <Route path='catalog/sets' element={<CatalogSetsWrapper />} />
        <Route path='catalog/minifigures' element={<CatalogMinifiguresWrapper />} />
        <Route path='catalog/parts' element={<CatalogPartsWrapper />} />
        <Route path='catalog/colors' element={<CatalogColorsWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
