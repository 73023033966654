// src/utils/loadStripeScript.ts
export const loadStripeScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]')) {
        resolve()
        return
      }
  
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/pricing-table.js'
      script.async = true
      script.onload = () => resolve()
      script.onerror = () => reject(new Error('Failed to load Stripe Pricing Table script'))
      document.body.appendChild(script)
    })
  }
  