import { PageTitle } from '../../_metronic/layout/core';

export function TermsOfUse() {
  return (
    <>
      {/* Page title */}
      <PageTitle breadcrumbs={[]}>Terms of Use</PageTitle>

      {/* Single large content card */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-12">
          <div className="card h-100">
            <div className="card-body">
              <h3 className="card-title">Terms of Use</h3>
              <p>
                Effective Date: December 27, 2024
              </p>
              <p>
                Welcome to StudFinder (the “App”). Please read these Terms of Use (“Agreement”)
                carefully before using the App. By accessing or using the App, you agree to be bound
                by these terms and conditions. If you do not agree to these terms, please do not use
                the App.
              </p>

              <h4>Acceptance of Terms</h4>
              <p>
                By accessing and using the App, you agree to comply with this Agreement. If you are
                using the App on behalf of an organization, you represent and warrant that you have
                the authority to bind that organization to these Terms of Use.
              </p>

              <h4>Changes to Terms</h4>
              <p>
                We reserve the right to modify or update this Agreement at any time. Any changes
                will be effective immediately upon posting. It is your responsibility to review
                these Terms periodically. Continued use of the App after any such changes
                constitutes your acceptance of the updated Terms.
              </p>

              <h4>User Account</h4>
              <p>
                To use certain features of the App, you may be required to create an account. You
                agree to provide accurate and up-to-date information when registering. You are
                responsible for maintaining the confidentiality of your account login information
                and for all activities under your account.
              </p>

              <h4>Use of the App</h4>
              <p>
                You agree to use the App solely for lawful purposes and in accordance with these
                Terms. You are prohibited from:
              </p>
              <ul>
                <li>
                  Using the App to engage in any unlawful activity, including but not limited to
                  fraud, data theft, or unauthorized access.
                </li>
                <li>
                  Distributing or storing content that violates intellectual property rights, is
                  offensive, or infringes upon privacy.
                </li>
              </ul>

              <h4>LEGO Investments and Portfolio Management</h4>
              <p>
                The App provides tools for managing and tracking LEGO investments and inventory.
                However, we do not provide financial or investment advice, nor do we guarantee the
                accuracy, profitability, or reliability of investment outcomes. You are solely
                responsible for your investment decisions. LEGO® is a trademark of the LEGO Group of
                companies which does not sponsor, authorize or endorse this site.
              </p>

              <h4>Intellectual Property</h4>
              <p>
                All content, including logos, text, graphics, and software, on the App is protected
                by copyright, trademark, and other intellectual property laws. You agree not to use,
                reproduce, or distribute any content from the App without prior written consent.
              </p>

              <h4>Images on StudFinder</h4>
              <p>
                Please note that the images displayed on the App, specifically on features such as
                StudFinder, are not owned by StudFinder. These images may be sourced from
                third-party content providers or user submissions. StudFinder does not claim
                ownership of these images and is not responsible for their accuracy, use, or
                distribution.
              </p>

              <h4>Site Uptime and Guarantees</h4>
              <p>
                StudFinder makes no guarantees regarding the availability, reliability, or uptime of
                the App or its features, including but not limited to the website StudFinder.app and
                its subdomains. The App may experience interruptions, outages, or maintenance
                periods that may affect your access to the services. You acknowledge and agree that
                StudFinder is not liable for any such interruptions or any losses, financial or data
                in nature, incurred as a result of service downtime.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
                To the fullest extent permitted by law, StudFinder and its affiliates shall not be
                liable for any indirect, incidental, special, or consequential damages, including
                but not limited to loss of profits, data, or investment, arising from your use of
                the App.
              </p>

              <h4>Contact Information</h4>
              <p>
                If you have any questions or concerns about these Terms, please contact us at:
              </p>
              <address>
                StudFinder.app
                <br />
                1243 Blackmer Ln
                <br />
                Columbia Falls, MT 59912
              </address>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
