import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./core/Auth";

export function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    // Perform logout logic
    const handleLogout = async () => {
      await logout(); // Wait for logout to complete if it's asynchronous
    };

    handleLogout();
  }, [logout]);

  // Navigate the user to the login page after logout
  return <Navigate to="/auth/login" replace/>; // clean history by replacing current url and avoid logout url trails in URL
}
