/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import { loadStripeScript } from '../../modals/loadStripeScript'

// Load Stripe with your public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)


const PurchaseDrawer = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  // Dynamically load the Stripe Pricing Table script
  useEffect(() => {
    loadStripeScript()
      .then(() => setIsScriptLoaded(true))
      .catch((error) => console.error('Failed to load Stripe script:', error))
  }, [])

  // Function to create a checkout session
  const handleCreateCheckoutSession = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/checkout`, {
        credentials: 'include',
        method: 'POST',
      })
      const data = await response.json()
      setClientSecret(data.clientSecret)
    } catch (error) {
      console.error('Failed to create checkout session:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    
    <div
      id='kt_purchase'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='purchase'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'100%', 'md': '100%'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_purchase_toggle'
      data-kt-drawer-close='#kt_purchase_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Submit Feedback</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_purchase_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
           {/* Stripe Pricing Table */}
           <div id="pricing-table-container">
           
<stripe-pricing-table pricing-table-id="prctbl_1QeP5eJvNk5TLGHCBugUTCL4"
publishable-key="pk_test_51QdP6sJvNk5TLGHCLmuRA37t733uBO6FxlZyBf0oj6Vyd7hgq5BzYUQ43u2NcziQTc6ShtGDuZKwqgIKgSBMLnSk00HZq8NUZG">
</stripe-pricing-table>
          </div>

            {/* begin::Stripe Checkout */}
            {/* <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              <h2 className='fw-bolder mb-5'>Complete Your Purchase</h2> */}

              {/* Show the button if clientSecret is not yet set */}
              {/* {!clientSecret ? (
                <button
                  className='btn btn-lg explore-btn-primary w-100'
                  onClick={handleCreateCheckoutSession}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Start Checkout'}
                </button>
              ) : (
                // Show the embedded checkout form once the client secret is set
                <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret}}>
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div> */}
            {/* end::Stripe Checkout */}

            {/* begin::Support */}
            {/* <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              <h2 className='fw-bolder mb-5'>Send an Email</h2>
              <div className='fs-5 fw-bold mb-5'>
                <span className='text-gray-500'>
                  Get in direct contact with a member of the StudFinder team using this email link.
                </span>
              </div>
              <a
                href='mailto:support@studfinder.app'
                className='btn btn-lg explore-btn-primary w-100'
              >
                Email Support
              </a>
            </div> */}
            {/* end::Support */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {PurchaseDrawer}
