/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import axios from 'axios'
import { signIn, fetchAuthSession, signOut } from 'aws-amplify/auth';
import axiosInstance from '../core/axiosInstance'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format.')
    .min(3, 'Minimum 3 symbols.')
    .max(50, 'Maximum 50 symbols.')
    .required('Email is required.'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols.')
    .max(50, 'Maximum 50 symbols.')
    .required('Password is required.'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      
      try {
        await signOut()
        // Attempt sign-in with AWS Cognito
        const {isSignedIn, nextStep} = await signIn({username: values.email, password: values.password});
        
        if (!isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          setLoading(false);
          return navigate('/auth/confirm', {state: {email: values.email, goToLocation: "/auth/login"}});
        }

        if (isSignedIn) {
          // Fetch tokens from Cognito
          const {tokens} = await fetchAuthSession() ?? {};
          const idToken = tokens?.idToken?.toString();

          const response = await axiosInstance.post(
            `${process.env.REACT_APP_API_URL}/auth/login`,
            { idToken },
            { withCredentials: true } // This is important to send cookies
          );
          
          saveAuth(response.data);
          setCurrentUser({
            ...response.data.user,
            permissions: response.data.user.permissions,
          });

          navigate('/dashboard');
          
          return;
        }
        setLoading(false);
        return;
      } catch (error) {
        console.error('Login error:', error);

        // Handle token expiration or unauthorized response
        if (axios.isAxiosError(error)) {
          setStatus('Session expired. Please log in again.');
        } else {
          setStatus('The login details are incorrect.');
        }
      } finally {
        setLoading(false);
        setSubmitting(false);
        saveAuth(undefined); // Clear the auth state on error
      }
    },
  });
  
  // const onGoogleLoginSuccess = async (codeResponse: any) => {
  //   await axios
  //     .post(`${process.env.REACT_APP_API_URL}/auth/google`, {authorizationCode: codeResponse.code})
  //     .then(async (res) => {
  //       localStorage.setItem('token', res.data.accessToken)
  //       saveAuth(res.data)
  //       const {data: user} = await getUserByToken(res.data.accessToken)
  //       setCurrentUser(user.user)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  // const onGoogleLoginFailure = (error: any) => {
  //   console.log('Google login was unsuccessful, please try again later!')
  //   console.log(error)
  // }

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>With Social Campaign</div> */}
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          {/* <GoogleOAuth
            onGoogleLoginSuccess={onGoogleLoginSuccess}
            onGoogleLoginFailure={onGoogleLoginFailure}
          /> */}
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div> */}
        {/* end::Separator */}

        {formik.status && 
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        }


        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a member yet?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up now!
          </Link>
        </div>
      </form>
    </>
  )
}