import React, {UIEvent, useMemo, useEffect, useState, useRef, useCallback} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  MaterialReactTable,
  MRT_Cell,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  type MRT_DensityState,
  type MRT_VisibilityState,
  useMaterialReactTable,
  MRT_EditActionButtons,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_Localization,
  MRT_TableInstance
} from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import {Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, CircularProgress, TextField, Checkbox, Dialog, FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants, TableContainer, Table, Paper, TableHead, TableCell, TableRow, TableBody, Grid} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {QueryClient, QueryClientProvider, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {Box, Typography, Select, MenuItem} from '@mui/material'
//Date Picker Imports
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {DatePickerInput, DatesRangeValue} from '@mantine/dates'
import SetLineChart from '../charts/SetLineChart'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useAuth } from '../../../../app/modules/auth'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import Papa from 'papaparse'; // For CSV parsing
import ExcelJS from 'exceljs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import axiosInstance from '../../../../app/modules/auth/core/axiosInstance';
import axios from 'axios';


// Extend dayjs with necessary plugins
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

interface CatalogSetsTableProps {
  className?: string // Make it optional
}

interface InventoryManagementTableProps {
  className?: string // Make it optional
  userSelectedSet?: string
}

interface SalesOrderModalProps {
  open: boolean;
  onClose: () => void;
  selectedRows: CombinedSets[];
  onSuccess: () => void;
}

type SetsApiResponse = {
  length: number;
  map: (
    arg0: (item: Sets) => {
      set_num: any;
      name: any;
      theme: any;
      num_parts: any;
      rrp_usd: any;
      img_url: any;
    }
  ) => any;
  data: Array<Sets>;
  meta: {
    totalRowCount: number;
  };
};

type Sets = {
  id?: number
  set?: any
  set_num: string
  name: string
  theme: string
  subtheme: string
  upc_barcode: string
  ean_barcode: string
  num_parts: number
  item_number: string
  boid: string
  dim_length_imperial: number
  dim_width_imperial: number
  dim_height_imperial: number
  weight_imperial: number
  dim_length_metric: number
  dim_width_metric: number
  dim_height_metric: number
  weight_metric: number
  date_released: Date
  date_retired: Date
  rrp_usd: number
  rrp_gbp: number
  rrp_cad: number
  rrp_eur: number
  amazon_price: number
  bl_lowest_new_price: number
  img_url: string
  minifig_count: number
  quantity?: number;
}

type Minifigures = {
  quantity: number
  img_url: string
  fig_num: string
  bricklink_num: string
  name: string
  num_parts: number
}

type UserSetInv = {
  id?: number
  user_id: number
  set_num: string
  quantity?: number
  price_paid: number
  date_acquired: Date
  collection: string
  location: string
  condition: string
  vendor: string
  taxes_fees: number
  product_received: boolean
  cb_received: boolean
  cb_source: string
  cb_percent: number
  order_number: string
  notes: string
}

type CombinedInvType = {
  set: Sets; // Reference to the Sets type
  userSet: UserSetInv; // Reference to the UserSetInv type
  
  // Root-level columns
  id: number;
  user_id: number;
  set_num: string;
  price_paid?: string | number; 
  quantity?: number;
  taxes_fees?: string | number;
  date_acquired?: string; // Assuming it's a string in ISO format
  vendor?: string;
  collection?: string;
  location?: string;
  condition?: string;
  product_received?: boolean;
  cb_received?: boolean;
  cb_source?: string;
  cb_percent?: string | number;
  order_number?: string;
  notes?: string;
};

type CombinedSets = Sets & {
  id: number;
  price_paid?: number;
  quantity?: number;
  amazon_price?: number;
  bl_lowest_new_price?: number;
  taxes_fees?: number;
  cb_percent?: number;
  date_acquired?: Date | string | null;
  vendor?: string;
  collection?: string;
  location?: string;
  condition?: string;
  product_received?: boolean;
  cb_received?: boolean;
  cb_source?: string;
  order_number?: string;
  notes?: string;
};

interface SelectedSetSubset {
  set_num: string;
  name: string;
  upc_barcode: string;
  date_retired: Date;
}

interface ImageGalleryItem {
  original: string;
  thumbnail: string;
  // ... any other properties you want to include
}

const AccordionManager = ({ selectedSet }: { selectedSet: SelectedSetSubset | null }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    const accordionBody = document.getElementById('kt_accordion_1_body_1');

    if (accordionBody) {
      const handleShow = () => setIsAccordionOpen(true);
      const handleHide = () => setIsAccordionOpen(false);

      accordionBody.addEventListener('show.bs.collapse', handleShow);
      accordionBody.addEventListener('hide.bs.collapse', handleHide);

      return () => {
        accordionBody.removeEventListener('show.bs.collapse', handleShow);
        accordionBody.removeEventListener('hide.bs.collapse', handleHide);
      };
    }
  }, []);

  return (
    <div data-bs-parent='#kt_accordion_1'>
      <div className='accordion-body'>
        {isAccordionOpen && (
          <SetLineChart 
            upc_barcode={selectedSet ? selectedSet.upc_barcode : ''} 
            type="BUY_BOX_SHIPPING"
            date_retired={selectedSet ? selectedSet.date_retired : new Date()} // passing the date_retired value
          />
        )}
      </div>
    </div>
  );
}

const InventoryManagementTable: React.FC<InventoryManagementTableProps> = ({className, userSelectedSet}) => {
  
  const [selectedSet, setSelectedSet] = useState<{ set_num: string, name: string, theme: string, subtheme: string, upc_barcode: string, ean_barcode: string,  img_url: string, num_parts: number, item_number: string, boid: string, dim_length_imperial: number, dim_width_imperial: number, dim_height_imperial: number, weight_imperial: number, dim_length_metric: number, dim_width_metric: number, dim_height_metric: number, weight_metric: number, minifig_count: number, date_released: Date, date_retired: Date, rrp_usd: number, rrp_gbp: number, rrp_cad: number, rrp_eur: number, } | null>(null);
  const [galleryImages, setGalleryImages] = useState<ImageGalleryItem[]>([]);

  const retiringSoonImageUrl = 'https://img.studfinder.app/RetiringSoon.png';
  const retiredImageUrl = 'https://img.studfinder.app/Retired.png';

  const [minifigures, setMinifigures] = useState<Minifigures[]>([]); // State to store minifigures

  const handleExportToCSV = () => {
    const visibleRows = InventoryManagementTable.getRowModel().rows.map(row => row.original);
    const csvData = Papa.unparse(visibleRows);
  
    // Create a blob and download the file
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'inventory.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSetClick = async (set: Sets) => {
    try {
      // Push a history state when the modal opens
      window.history.pushState({ modalOpen: true }, '', `#-${set.set_num}`);
  
      // Clear previous data
      setSelectedSet(null);
      setGalleryImages([]);
      setMinifigures([]);
  
      // Perform API calls using axiosInstance
      const [imagesResponse, minifigsResponse] = await Promise.all([
        axiosInstance.get(`/sets/${set.set_num}/images/`),
        axiosInstance.get(`/minifigures/${set.set_num}/`)
      ]);
  
      const images = imagesResponse.data.images;
      const minifigData = minifigsResponse.data;
  
      // Format images for the gallery
      const formattedGalleryImages: ImageGalleryItem[] = images.map((url: string) => ({
        original: url,
        thumbnail: url,
      }));
  
      // Set state with the fetched data
      setMinifigures(minifigData.data); // Assuming the API returns the minifigures array in data
      setGalleryImages(formattedGalleryImages);
  
      // Set selected set data
      setSelectedSet({
        set_num: set.set_num,
        name: set.name,
        theme: set.theme,
        subtheme: set.subtheme,
        upc_barcode: set.upc_barcode,
        ean_barcode: set.ean_barcode,
        num_parts: set.num_parts,
        item_number: set.item_number,
        boid: set.boid,
        dim_length_imperial: set.dim_length_imperial,
        dim_width_imperial: set.dim_width_imperial,
        dim_height_imperial: set.dim_height_imperial,
        weight_imperial: set.weight_imperial,
        dim_length_metric: set.dim_length_metric,
        dim_width_metric: set.dim_width_metric,
        dim_height_metric: set.dim_height_metric,
        weight_metric: set.weight_metric,
        rrp_usd: set.rrp_usd,
        rrp_gbp: set.rrp_gbp,
        rrp_cad: set.rrp_cad,
        rrp_eur: set.rrp_eur,
        minifig_count: set.minifig_count,
        img_url: images[0],
        date_released: new Date(set.date_released),
        date_retired: new Date(set.date_retired),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      const modalElement = document.getElementById('kt_modal_2');
  
      if (modalElement?.classList.contains('show')) {
        // Close the modal
        modalElement.classList.remove('show');
        modalElement.style.display = 'none';
  
        // Remove body classes and reset styles
        document.body.classList.remove('modal-open');
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('z-index');
  
        // Remove any remaining backdrop
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
  
        // Reset selected set and modal content
        setSelectedSet(null);
        setGalleryImages([]);
        setMinifigures([]);
  
        // 🛠 Force a re-render to ensure UI elements are fully interactive
        setTimeout(() => {
          document.body.click();
        }, 0);
      }
    };
  
    // Add event listener
    window.addEventListener('popstate', handlePopState);
  
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [selectedSet]);


  // Helper function to determine if a set is retiring soon or already retired
  const getRetirementImage = () => {
    if (!selectedSet || !selectedSet.date_retired) return null;

    const today = new Date();
    const retirementDate = new Date(selectedSet.date_retired);
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(today.getMonth() + 6);

    if (retirementDate < today) {
      return retiredImageUrl;
    } else if (retirementDate < sixMonthsFromNow) {
      return retiringSoonImageUrl;
    }

    return undefined;  // No image if not retiring soon or retired
  };

    

  const [setNum, setSetNum] = useState('');
  const [setName, setSetName] = useState('');
  const [error, setError] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [pricePaid, setPricePaid] = useState(0);
  const [taxesFees, setTaxesFees] = useState(0);
  const [dateAcquired, setDateAcquired] = useState<dayjs.Dayjs | null>(dayjs());
  const [collection, setCollection] = useState('');
  const [location, setLocation] = useState('');
  const [condition, setCondition] = useState('');
  const [vendor, setVendor] = useState(''); // For the 'Vendor' field
  const [productReceived, setProductReceived] = useState(false); // For the 'Product Received' checkbox
  const [cashbackReceived, setCashbackReceived] = useState(false); // For the 'Cashback Received' checkbox
  const [cashbackSource, setCashbackSource] = useState(''); // For the 'Cashback Source' field
  const [cashbackPercent, setCashbackPercent] = useState(0); // For the 'Cashback Percent' field
  const [orderNumber, setOrderNumber] = useState(''); // For the 'Order Number' field
  const [notes, setNotes] = useState(''); // For the 'Notes' field
  // const [localNotes, setLocalNotes] = useState(notes); // Local state for notes

  // const handleSave = () => {
  //   setNotes(localNotes); // Update the parent state or make the API call
  //   // Close the dialog
  // };
  
const [debouncedValue, setDebouncedValue] = useState(setNum);

const debounceDelay = 500; // Delay in milliseconds

const debouncedHandleSetNumChange = useCallback(
  (value: string) => {
    const timer = setTimeout(async () => {
      setSetNum(value); // Update the state only after debounce delay

      try {
        // Use axiosInstance to fetch the set data
        const response = await axiosInstance.get(`/sets/${value}`, {
          withCredentials: true, // Ensures cookies are sent with the request
        });

        console.log('Set data:', response.data);
      } catch (err) {
        console.error('Error in handleSetNumChange logic:', err);
      }
    }, debounceDelay);

    return () => clearTimeout(timer); // Clear timeout if the value changes
  },
  [debounceDelay] // Dependency array
);

// Update the useEffect block to trigger debounced logic
useEffect(() => {
  if (setNum !== debouncedValue) {
    const timer = setTimeout(() => {
      setSetNum(debouncedValue);
      handleSetNumChange(debouncedValue);
    }, debounceDelay);

    return () => clearTimeout(timer);
  }
}, [debouncedValue]);

useEffect(() => {
  if (userSelectedSet) {
    setColumnFilters([{ id: 'set_num', value: userSelectedSet }]);
  }
}, [userSelectedSet]);

  const handleSetNumChange = async (value: string) => {
    try {
      // Make a request to the API using axiosInstance
      const response = await axiosInstance.get(`/sets/${value}`, {
        withCredentials: true, // Ensures cookies (accessToken) are sent with the request
      });

      // Set the set name if the request is successful
      setSetName(response.data.name || 'Unknown Set Name');
      setError(''); // Clear any previous errors
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
        setSetName('');
        setError('Set number not found in the database');
      } else {
        console.error('Error validating set number:', error);
        setError('Failed to validate set number');
      }
    }
  };
  
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
      userSelectedSet
        ? [{ id: 'set_num', value: userSelectedSet }] // Set initial filter if defaultSetNum is provided
        : []
    );
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [rowCount, setRowCount] = useState(0)
  // State to store the total inventory rows for the user
  const [totalInventoryRows, setTotalInventoryRows] = useState(0);

  const { data, isError, isFetching, isLoading, refetch } = useQuery<SetsApiResponse>(
    ['table-data', columnFilters, globalFilter, sorting],
    async () => {
      const fetchURL = new URL(
        '/users/sets/inventory',
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_API_URL}`
          : 'http://localhost:3000'
      );
  
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('search', globalFilter ?? '');
      fetchURL.searchParams.set('sort', JSON.stringify(sorting ?? []));
  
      try {
        // Perform the request using axiosInstance
        const response = await axiosInstance.get<SetsApiResponse>(fetchURL.pathname, {
          params: fetchURL.searchParams,
          withCredentials: true, // Ensures cookies are sent with the request
        });
  
        // Set the total inventory rows on the first fetch
        if (!totalInventoryRows && response.data.meta?.totalRowCount) {
          setTotalInventoryRows(response.data.meta.totalRowCount);
        }
  
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          // Return an empty valid response adhering to `SetsApiResponse` type
          return {
            length: 0,
            map: () => [],
            data: [],
            meta: { totalRowCount: 0 },
          } as SetsApiResponse;
        }
        throw new Error('Failed to fetch inventory data');
      }
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const flatData = useMemo(() => {
      if (!data || isLoading) {
    return []; // Avoid processing if data isn't ready
  }
    return (
      data?.data.map((item: Partial<CombinedInvType>) => ({
        ...item.set, // Spread properties from `set`
        ...(item.userSet ?? {}), // Spread properties from `userSet` if available
        set: item.set,
        set_num: item.set?.set_num ?? 'N/A',
        name: item.set?.name ?? 'N/A',
        theme: item.set?.theme ?? 'N/A',
        subtheme: item.set?.subtheme ?? 'N/A',
        dim_height_imperial: Number(item.set?.dim_height_imperial) || 0, // Ensure numeric value
        dim_height_metric: Number(item.set?.dim_height_metric) || 0, // Ensure numeric value
        dim_length_imperial: Number(item.set?.dim_length_imperial) || 0, // Ensure numeric value
        dim_length_metric: Number(item.set?.dim_length_metric) || 0, // Ensure numeric value
        dim_width_imperial: Number(item.set?.dim_width_imperial) || 0, // Ensure numeric value
        dim_width_metric: Number(item.set?.dim_width_metric) || 0, // Ensure numeric value
        weight_imperial: Number(item.set?.weight_imperial) || 0, // Ensure numeric value
        weight_metric: Number(item.set?.weight_metric) || 0, // Ensure numeric value
        boid: item.set?.boid ?? 'N/A',
        minifig_count: Number(item.set?.minifig_count) || 0, // Ensure numeric value
        upc_barcode: item.set?.upc_barcode ?? 'N/A',
        ean_barcode: item.set?.ean_barcode ?? 'N/A',
        date_released: item.set?.date_released ? new Date(item.set.date_released) : new Date(0),
        date_retired: item.set?.date_retired ? new Date(item.set.date_retired) : new Date(0),
        num_parts: Number(item.set?.num_parts) || 0, // Ensure numeric value
        rrp_usd: Number(item.set?.rrp_usd) || 0, // Ensure numeric value
        rrp_cad: Number(item.set?.rrp_cad) || 0, // Ensure numeric value
        rrp_eur: Number(item.set?.rrp_eur) || 0, // Ensure numeric value
        rrp_gbp: Number(item.set?.rrp_gbp) || 0, // Ensure numeric value
        item_number: item.set?.item_number ?? 'N/A',
        img_url: item.set?.img_url ?? '',
        amazon_price: Number(item.set?.amazon_price) || 0, // Ensure numeric value
        bl_lowest_new_price: Number(item.set?.bl_lowest_new_price) || 0, // Ensure numeric value
        quantity: item.userSet?.quantity ?? 1, // Use quantity from userSet
        price_paid: Number(item.price_paid) || 0, // Convert to number and use 0 if missing
        taxes_fees: Number(item.taxes_fees) || 0, // Convert to number and use 0 if missing
        date_acquired: item.date_acquired ? new Date(item.date_acquired) : null,
        vendor: item.vendor ?? '',
        collection: item.collection ?? '',
        location: item.location ?? '',
        condition: item.condition ?? '',
        product_received: item.product_received ?? false,
        cb_received: item.cb_received ?? false,
        cb_source: item.cb_source ?? '',
        cb_percent: Number(item.cb_percent) || 0,
        order_number: item.order_number ?? '',
        notes: item.notes ?? '',
        id: Number(item.id) || 0,
      })) ?? []
    );
  }, [data]);
  
  console.log('Flat Data:', flatData);
  
  // const totalDBRowCount = data?.meta?.totalRowCount ?? 0;
  const totalFetched = flatData.length;


  const uniqueConditionOptions = useMemo(() => {
    if (isLoading || !flatData) return [];
    const conditions = flatData
      .map((item) => item.condition)
      .filter(Boolean); // Remove null/undefined
      console.log('Unique Conditions:', Array.from(new Set(conditions))); // Log the values
    return Array.from(new Set(conditions)); // Remove duplicates
  }, [flatData, isLoading]);

  const uniqueCollectionOptions = useMemo(() => {
    const collections = flatData.map((item) => {
      return item.collection;
    }).filter(Boolean); // Remove null/undefined
    return Array.from(new Set(collections)); // Remove duplicates and return as an array
  }, [flatData]);

  const uniqueLocationOptions = useMemo(() => {
    const locations = flatData.map((item) => {
      return item.location;
    }).filter(Boolean); // Remove null/undefined
    return Array.from(new Set(locations)); // Remove duplicates and return as an array
  }, [flatData]);

  const uniqueVendorOptions = useMemo(() => {
    const vendors = flatData.map((item) => {
      return item.vendor;
    }).filter(Boolean); // Remove null/undefined
    return Array.from(new Set(vendors)); // Remove duplicates and return as an array
  }, [flatData]);

  const uniqueCBSourceOptions = useMemo(() => {
    const cb_sources = flatData.map((item) => {
      return item.cb_source;
    }).filter(Boolean); // Remove null/undefined
    return Array.from(new Set(cb_sources)); // Remove duplicates and return as an array
  }, [flatData]);

  
  
  const columns = useMemo<MRT_ColumnDef<Sets>[]>(
    () => [
      // {
      //   accessorKey: 'thumbnail',
      //   enableClickToCopy: false,
      //   header: 'Thumbnail',
      //   size: 150,
      //   enableColumnFilter: false,
      //   Cell: ({ cell, row }) => {
      //     const setNum = row.original.set_num; // Use set number to construct image URL
      //     const imgUrl = `https://cdn.studfinder.app/${setNum}%2F${setNum}_image1.jpg`; // Construct the image URL
        
      //     return (
      //       <div onClick={() => handleSetClick(row.original)}>
      //         {imgUrl ? (
      //           <img 
      //             src={imgUrl} 
      //             onError={(e) => (e.currentTarget.style.display = 'none')} // Hide the image if it fails to load
      //             height={50} 
      //             loading="lazy"  // Lazy load images
      //             data-bs-toggle="modal" 
      //             data-bs-target="#kt_modal_2"
      //             style={{ cursor: 'pointer' }}
      //           />
      //         ) : (
      //           <div /> // Return empty div if there's no image
      //         )}
      //       </div>
      //     );
      //   },
      // },     
      {
        accessorKey: 'set_num',
        header: 'Set Number',
        size: 150,
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ cell, row }) => {
          const setNum = cell.getValue<string>()
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setNum}
            </div>
          )
        },
        muiTableFooterCellProps: {
          align: 'left', // Ensure footer aligns with column
        },
      },      
      {
        accessorKey: 'name',
        header: 'Set Name',
        size: 150,
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ cell, row }) => {
          const setName = cell.getValue<string>()
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setName}
            </div>
          )
        },
      },
      // {
      //   accessorKey: 'quantity',
      //   header: 'Quantity',
      //   enableEditing: false,
      //   size: 100,
      // },
      {
        accessorFn: (row) => {
          const amazonPrice = row.amazon_price || 0;
          const blLowestNewPrice = row.bl_lowest_new_price || 0;
        
          // Conditional logic to handle different cases
          if (amazonPrice > 0 && blLowestNewPrice > 0) {
            return Math.min(amazonPrice, blLowestNewPrice); // Both prices are available, use the lesser
          } else if (amazonPrice > 0) {
            return amazonPrice; // Only amazonPrice is available
          } else if (blLowestNewPrice > 0) {
            return blLowestNewPrice; // Only blLowestNewPrice is available
          } else {
            return 0; // Neither price is available
          }
        },
        id: 'current_value', // A unique ID for the column
        header: 'Current Value',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        enableSorting: true,
        Cell: ({ cell }) => {
          const rawValue = cell.getValue(); // Get the calculated value
          const value = Number(rawValue); // Ensure it's a number
          if (isNaN(value) || value === 0) {
            return 'N/A'; // Handle invalid or zero values
          }
          return `$${value.toFixed(2)}`; // Format valid values as currency
        },
        size: 150, // Optional: Set column width
        muiTableBodyCellProps: {
          align: 'left', // Align cell contents
        },
        muiTableFooterCellProps: {
          align: 'left', // Align footer contents
          sx: {
            fontWeight: 'bold', // Make the text bold
            fontSize: '0.8rem', // Increase font size slightly
          },
        },
        Footer: ({ table }) => {
          // Calculate the total for Current Value
          const totalCurrentValue = table.getFilteredRowModel().rows.reduce(
            (total, row) => total + (Number(row.getValue<number>('current_value')) || 0),
            0
          );
      
          // Calculate the total for Price Paid
          const totalPricePaid = table.getFilteredRowModel().rows.reduce(
            (total, row) => total + (Number(row.getValue<number>('price_paid')) || 0),
            0
          );
      
          // Calculate the total for Taxes & Fees
          const totalTaxesFees = table.getFilteredRowModel().rows.reduce(
            (total, row) => total + (Number(row.getValue<number>('taxes_fees')) || 0),
            0
          );
      
          // // Calculate Margin
          // const margin = totalCurrentValue - totalPricePaid - totalTaxesFees;
      
          // // Calculate ROI
          // const roi = totalPricePaid > 0 ? (margin / totalPricePaid) * 100 : 0;
      
          return (
            <div>
              <div>Total: ${totalCurrentValue.toFixed(2)}</div>
              {/* <div>Margin: ${margin.toFixed(2)}</div>
              <div>ROI: {roi.toFixed(2)}%</div>
              <div><i>Excludes selling costs</i></div> */}
            </div>
          );
        }
      },
      {
        accessorKey: 'price_paid',
        header: 'Price Paid',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          const value = Number(cell.getValue<number>());
          return !isNaN(value) ? `$${value.toFixed(2)}` : 'N/A';
        },
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          type: 'number',
        },
        Footer: ({ table }) => {
          const totalPricePaid = table
            .getFilteredRowModel()
            .rows.reduce(
              (total, row) =>
                total +
                (Number(row.getValue<number>('price_paid')) || 0),
              0
            );
          return `Total: $${totalPricePaid.toFixed(2)}`;
        },
        muiTableFooterCellProps: {
          align: 'left', // Ensure footer aligns with column
          sx: {
            fontWeight: 'bold', // Make the text bold
            fontSize: '0.8rem', // Increase font size slightly
          },
        },
      },
      {
        accessorKey: 'taxes_fees',
        header: 'Taxes & Fees',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          const value = Number(cell.getValue<number>());
          return !isNaN(value) ? `$${value.toFixed(2)}` : ''; // Handle NaN case
        },
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          type: 'number',
        },
        Footer: ({ table }) => {
          const totalTaxesFees = table
            .getFilteredRowModel()
            .rows.reduce(
              (total, row) =>
                total +
                (Number(row.getValue<number>('taxes_fees')) || 0),
              0
            );
          return `Total: $${totalTaxesFees.toFixed(2)}`;
        },
        muiTableFooterCellProps: {
          align: 'left', // Ensure footer aligns with column
          sx: {
            fontWeight: 'bold', // Make the text bold
            fontSize: '0.8rem', // Increase font size slightly
          },
        },
      },           
      {
        accessorKey: 'amazon_price',
        header: 'Amazon Price',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          const value = Number(cell.getValue<number>());
          return !isNaN(value) ? `$${value.toFixed(2)}` : ''; // Handle NaN case
        },
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          type: 'number',
        },
      },     
      {
        accessorKey: 'bl_lowest_new_price',
        header: 'BrickLink Price',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          const value = Number(cell.getValue<number>());
          return !isNaN(value) ? `$${value.toFixed(2)}` : ''; // Handle NaN case
        },
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          type: 'number',
        },
      },   
      {
        accessorKey: 'date_acquired',
        header: 'Date Acquired',
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          type: 'date',
        },
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<Date>();
          if (!dateValue) return 'N/A';
        
          // Convert to an equivalent "UTC-based" date
          const utcYear = dateValue.getUTCFullYear();
          const utcMonth = String(dateValue.getUTCMonth() + 1).padStart(2, '0');
          const utcDay = String(dateValue.getUTCDate()).padStart(2, '0');
        
          return `${utcMonth}/${utcDay}/${utcYear}`;
        },
        Header: ({ column }) => <em>{column.columnDef.header}</em>, // custom header markup
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type="range"
              clearable
              placeholder="Select Date Range"
              valueFormat="MM/DD/YY"
              onChange={(newValue: any) => {
                column.setFilterValue(newValue); // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: 'collection',
        header: 'Collection',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueCollectionOptions,
        filterFn: 'equals',
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueLocationOptions,
        filterFn: 'equals',
      },
      {
        accessorKey: 'condition',
        header: 'Condition',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueConditionOptions,
        filterFn: 'equals',
      },
      {
        accessorKey: 'vendor',
        header: 'Vendor',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueVendorOptions,
        filterFn: 'equals',
      },
      {
        accessorKey: 'product_received',
        header: 'Product Received',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return (
            <input
              type="checkbox"
              checked={value}
              disabled
            />
          );
        },
        EditComponent: ({
          cell,
          row,
          column,
          table,
        }: {
          cell: MRT_Cell<any>;
          row: MRT_Row<any>;
          column: MRT_ColumnDef<any>;
          table: MRT_TableInstance<any>;
        }) => {
          const value = cell.getValue<boolean>();
          return (
            <input
              type="checkbox"
              checked={value}
              onChange={(e) => {
                const newValue = e.target.checked; // Get the new value from the checkbox
                // Update the edited row value
                table.setEditingRow({
                  ...row.original,
                  [column.id as any]: newValue,
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: 'cb_received',
        header: 'Cashback Received',
        filterVariant: 'checkbox', // Ensure it's treated as a boolean filter
        Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
          const value = cell.getValue<boolean>();
          return (
            <input
              type="checkbox"
              checked={value}
              disabled // Disable checkbox in view mode
            />
          );
        },
        EditComponent: ({
          cell,
          row,
          column,
          table,
        }: {
          cell: MRT_Cell<any>;
          row: MRT_Row<any>;
          column: MRT_ColumnDef<any>;
          table: MRT_TableInstance<any>;
        }) => {
          const value = cell.getValue<boolean>();
          return (
            <input
              type="checkbox"
              checked={value}
              onChange={(e) => {
                const newValue = e.target.checked; // Get the new value from the checkbox
                // Update the edited row value
                table.setEditingRow({
                  ...row.original,
                  [column.id as any]: newValue,
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: 'cb_source',
        header: 'Cashback Source',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueCBSourceOptions,
        filterFn: 'equals',
      },
      {
        accessorKey: 'cb_percent',
        header: 'Cashback Percent',
        Cell: ({ cell }) => {
          const value = Number(cell.getValue<number>());
          return !isNaN(value) ? `${value.toFixed(2)}%` : ''; // Handle NaN case
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 100,
        muiTableBodyCellEditTextFieldProps: {
          type: 'number',
        },
      },
      {
        accessorKey: 'order_number',
        header: 'Order Number',
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          placeholder: 'Order Number',
        },
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 150,
        muiTableBodyCellEditTextFieldProps: {
          placeholder: 'Notes',
        },
      },
      {
        accessorKey: 'theme',
        header: 'Theme',
        enableGlobalFilter: true,
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: 'subtheme',
        header: 'Subtheme',
        enableGlobalFilter: true,
        enableEditing: false,
        size: 120,
      },
      {
        accessorKey: 'rrp_usd',
        header: 'MSRP ($)',
        enableEditing: false,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `$${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      // {
      //   accessorKey: 'rrp_gbp',
      //   header: 'MSRP (£)',
      //   enableEditing: false,
      //   Cell: ({ cell }) => {
      //     const value = cell.getValue();
      //     return value != null ? `£${value}` : ''; // Return empty string if null
      //   },
      //   filterVariant: 'range',
      //   filterFn: 'betweenInclusive',
      //   columnFilterDisplayMode: 'popover',
      // },
      // {
      //   accessorKey: 'rrp_cad',
      //   header: 'MSRP (C$)',
      //   enableEditing: false,
      //   Cell: ({ cell }) => {
      //     const value = cell.getValue();
      //     return value != null ? `C$${value}` : ''; // Return empty string if null
      //   },
      //   filterVariant: 'range',
      //   filterFn: 'betweenInclusive',
      //   columnFilterDisplayMode: 'popover',
      // },
      // {
      //   accessorKey: 'rrp_eur',
      //   header: 'MSRP (€)',
      //   enableEditing: false,
      //   Cell: ({ cell }) => {
      //     const value = cell.getValue();
      //     return value != null ? `€${value}` : ''; // Return empty string if null
      //   },
      //   filterVariant: 'range',
      //   filterFn: 'betweenInclusive',
      //   columnFilterDisplayMode: 'popover',
      // },
      {
        accessorKey: 'num_parts',
        Cell: ({ cell }) => cell.getValue<number>().toLocaleString(),
        header: 'Pieces',
        enableEditing: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 150,
        muiFilterSliderProps: {
          marks: true,
          max: 12000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
      {
        accessorKey: 'minifig_count',
        header: 'Minifigures',
        enableEditing: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorFn: (row) => new Date(row.date_released), // Convert to Date for sorting and filtering
        id: 'date_released',
        header: 'Released',
        enableEditing: false,
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => new Date(row.date_retired),  // Convert to Date for sorting and filtering
        id: 'date_retired',
        header: 'Retired',
        enableEditing: false,
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
    ],
    [uniqueConditionOptions, // Ensure column recalculates on changes
      uniqueCollectionOptions,
      uniqueLocationOptions,
      uniqueVendorOptions,
      uniqueCBSourceOptions]
  )

  const InventoryManagementTable = useMaterialReactTable({
    columns,
    data: flatData || [],
    renderEmptyRowsFallback: () => (
      <tr>
        <td colSpan={columns.length} style={{ textAlign: 'center', padding: '16px' }}>
          <Typography variant="body2">No inventory found.</Typography>
        </td>
      </tr>
    ),
    localization:{
          selectedCountOfRowCountRowsSelected: "{selectedCount} rows selected"
    },
    enableEditing: true,
    enableGlobalFilter: true,
    editDisplayMode: 'row',
    columnFilterDisplayMode: 'subheader',
    enableClickToCopy: false,
    enableRowSelection: true,
    enableBatchRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => {
      if (row.id !== undefined && row.id !== null) {
        return row.id.toString();
      }
      throw new Error(`Row ID is missing or invalid. Row: ${JSON.stringify(row)}`);
    },
    enableRowVirtualization: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableRowPinning: false,
    enableRowActions: true,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: {overscan: 10}, 
    initialState: {
      showColumnFilters: true,
      columnOrder: [
        'current_value',
        'amazon_price',
        'bl_lowest_new_price',
        'price_paid',
        'taxes_fees',
        'condition',
        'collection',
        'date_acquired',
        'vendor',
        'location',
      ],
      columnPinning: { left: ['mrt-row-select', 'mrt-row-actions', 'set_num', 'name'] },
      columnVisibility: {
        date_released: false,
      },
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
      size: 30, // Adjust the width of the selection column
      muiTableHeadCellProps: {
        align: 'center', // Center align header content
        sx: { width: '40px', minWidth: '40px', padding: '0' }, // Set explicit width
      },
      muiTableBodyCellProps: {
        align: 'center', // Center align body content
        sx: { width: '40px', minWidth: '40px', padding: '0' }, // Set explicit width
      },
      muiTableFooterCellProps: {
        align: 'center', // Center align body content
        sx: { width: '40px', minWidth: '40px', padding: '0' }, // Set explicit width
      },
    },
      'mrt-row-actions': {
        size: 100,
        grow: false,
        muiTableHeadCellProps: {
          align: 'left', // Alignment for the header
          sx: { 
            width: '100px', 
            position: 'sticky', 
          },
        },
        muiTableBodyCellProps: {
          sx: { 
            width: '100px', 
            textAlign: 'left', 
            position: 'sticky',
          },
        },
        muiTableFooterCellProps: {
          sx: { 
            width: '100px', 
            textAlign: 'left', 
            position: 'sticky',
          },
        },
      },
      'mrt-row-numbers': {
        enableColumnOrdering: true, // Turn on some features that are usually off
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
      <div>
          {/* <Typography>Pieces: {row.original.num_parts.toString()}</Typography> */}
          <Typography>Theme: {row.original.theme}</Typography>
        </div>
      </Box>),
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {maxHeight: '400px',
           tableLayout: 'fixed',
      },
    //   onScroll: (
    //     event: UIEvent<HTMLDivElement> //add an event listener to the table container element
    //   ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data.',
        }
      : undefined,
      renderTopToolbarCustomActions: ({ table }) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            onClick={() => {
              // Just set it to true
              table.setCreatingRow(true);
            }}
          >
            Add Inventory
          </Button>
          <Button
            variant="contained"
            onClick={handleResetFilters} // Call the resetFilters function
          >
            Reset Filters
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              if (selectedRows.length === 0) {
                alert("Please select at least one row to create a sales order.");
                return;
              }
              setSalesOrderModalOpen(true);
            }}
          >
          Create Sales Order
        </Button>
          <Button
            variant="contained"
            onClick={() => setImportModalOpen(true)} // Open modal
          >
            Import CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportToCSV}
          >
            Export CSV
          </Button>
          <Button
            variant="contained"
            color="error" // Make the button red
            onClick={handleDeleteSelected} // Call the delete handler function
          >
            Delete Selected
          </Button>
        </div>
      ),
    renderBottomToolbarCustomActions: () => (
      <Typography>
        Fetched {totalFetched} of {totalInventoryRows.toLocaleString()} total rows. Good boy! ˁ(⚆ᴥ⚆)ˀ
      </Typography>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '0.1rem' }}>
        {savingRowId === row.original.id || savingRowId === 0 ? (
          <Tooltip title="Saving...">
            <IconButton disabled>
              <CircularProgress size={24} />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => openDeleteConfirmModal(row.original.id!)} // Pass row.original.id
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => {
      const handleSetNumChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSetNum(value);
    
        if (value.trim() === '') {
          setSetName('');
          setError('Set number cannot be empty');
          return;
        }
    
        try {
          // Make a request to the API using axiosInstance
          const response = await axiosInstance.get(`/sets/${value}`, {
            withCredentials: true, // Ensures cookies are sent with the request
          });
        
          // Set the set name if the request is successful
          setSetName(response.data.name || 'Unknown Set Name');
          setError(''); // Clear any previous error
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            setSetName('');
            setError('Set number not found in the database');
          } else {
            console.error('Error validating set number:', error);
            setError('Failed to validate set number');
          }
        }
      };
      
      
      return (
        <Dialog
      open={true} // Ensure the modal is controlled by a boolean `open` state
      onClose={() => {
        // Handle close logic
        table.setCreatingRow(null); // Close the create row modal
      }}
      disableEnforceFocus // This disables the enforce focus behavior
      fullWidth
      maxWidth="sm"
    >
          <DialogTitle variant="h4">Add New Inventory</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            onClick={(e) => e.stopPropagation()}
          >
            <TextField
            label="Set Number"
            value={debouncedValue} // Use the debounced value here
            onChange={(e) => setDebouncedValue(e.target.value)} // Update debounced value on input change
            helperText={error || (setName && `Set Name: ${setName}`)}
            error={!!error}
            onMouseDown={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
          />
            <TextField
  label="Quantity"
  value={quantity === 0 ? '' : quantity} // Display an empty string if 0
  onChange={(e) => {
    let rawValue = e.target.value;

    // Allow only valid numbers (integers)
    if (/^[0-9]*$/.test(rawValue)) {
      // Remove leading zeros
      if (rawValue.startsWith('0') && rawValue !== '0') {
        rawValue = rawValue.replace(/^0+/, '');
      }

      setQuantity(rawValue === '' ? 0 : parseInt(rawValue, 10)); // Convert to number
    }
  }}
  type="text" // Use text to fully control formatting
  onFocus={(e) => {
    e.stopPropagation();
    if (quantity === 0) setQuantity(0); // Clear field for editing if 0
  }}
  onBlur={() => {
    if (!quantity || isNaN(quantity)) {
      setQuantity(0); // Reset to 0 if invalid
    }
  }}
  onMouseDown={(e) => e.stopPropagation()}
/>
            <TextField
              label="Price Paid"
              value={pricePaid === 0 ? '' : pricePaid} // Display empty string if 0, otherwise show the number
              onChange={(e) => {
                let rawValue = e.target.value;

                // Allow only valid numbers and a single decimal point
                if (/^[0-9]*\.?[0-9]*$/.test(rawValue)) {
                  // Remove leading zeros by trimming them manually
                  if (rawValue.startsWith('0') && !rawValue.startsWith('0.')) {
                    rawValue = rawValue.replace(/^0+/, ''); // Remove leading zeros
                  }

                  setPricePaid(rawValue === '' ? 0 : parseFloat(rawValue)); // Always pass a number to state
                }
              }}
              type="number" // Use text to handle input flexibly
              InputProps={{
                startAdornment: <span style={{ marginRight: '4px' }}>$</span>, // Dollar sign
              }}
              onFocus={(e) => {
                e.stopPropagation();
                if (pricePaid === 0) setPricePaid(0); // Clear field for editing if the value is 0
              }}
              onBlur={() => {
                if (!pricePaid || isNaN(pricePaid)) {
                  setPricePaid(0); // Reset to 0 if invalid or empty
                } else {
                  setPricePaid(Number(pricePaid.toFixed(2))); // Format as a proper number with 2 decimals
                }
              }}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <TextField
  label="Taxes & Fees"
  value={taxesFees === 0 ? '' : taxesFees} // Display an empty string if 0
  onChange={(e) => {
    let rawValue = e.target.value;

    // Allow only valid numbers and a single decimal point
    if (/^[0-9]*\.?[0-9]*$/.test(rawValue)) {
      // Remove leading zeros unless it's a decimal like "0."
      if (rawValue.startsWith('0') && !rawValue.startsWith('0.')) {
        rawValue = rawValue.replace(/^0+/, '');
      }

      setTaxesFees(rawValue === '' ? 0 : parseFloat(rawValue)); // Convert to number
    }
  }}
  type="number"
  InputProps={{
    startAdornment: <span style={{ marginRight: '4px' }}>$</span>, // Dollar sign
  }}
  onFocus={(e) => {
    e.stopPropagation();
    if (taxesFees === 0) setTaxesFees(0); // Clear field for editing
  }}
  onBlur={() => {
    if (!taxesFees || isNaN(taxesFees)) {
      setTaxesFees(0); // Reset to 0 if invalid
    } else {
      setTaxesFees(Number(taxesFees.toFixed(2))); // Format to 2 decimals
    }
  }}
  onMouseDown={(e) => e.stopPropagation()}
/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Acquired"
                value={dateAcquired}
                onChange={(newValue) => setDateAcquired(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
            <TextField
              label="Collection"
              value={collection}
              onChange={(e) => setCollection(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
              label="Condition"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
              label="Vendor"
              value={vendor}
              onChange={(e) => setVendor(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
              label="Cashback Source"
              value={cashbackSource}
              onChange={(e) => setCashbackSource(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
  label="Cashback Percent"
  value={cashbackPercent === 0 ? '' : cashbackPercent} // Display an empty string if 0
  onChange={(e) => {
    let rawValue = e.target.value;

    // Allow only valid numbers and a single decimal point
    if (/^[0-9]*\.?[0-9]*$/.test(rawValue)) {
      // Remove leading zeros unless it's a decimal like "0."
      if (rawValue.startsWith('0') && !rawValue.startsWith('0.')) {
        rawValue = rawValue.replace(/^0+/, '');
      }

      setCashbackPercent(rawValue === '' ? 0 : parseFloat(rawValue)); // Convert to number
    }
  }}
  type="number"
  InputProps={{
    startAdornment: <span style={{ marginRight: '4px' }}>%</span>, // Percent sign
  }}
  onFocus={(e) => {
    e.stopPropagation();
    if (cashbackPercent === 0) setCashbackPercent(0); // Clear field for editing
  }}
  onBlur={() => {
    if (!cashbackPercent || isNaN(cashbackPercent)) {
      setCashbackPercent(0); // Reset to 0 if invalid
    } else {
      setCashbackPercent(Number(cashbackPercent.toFixed(2))); // Format to 2 decimals
    }
  }}
  onMouseDown={(e) => e.stopPropagation()}
/>
            <TextField
              label="Order Number"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <TextField
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)} // Only update local state
              multiline
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
            />
            <Box display="flex" alignItems="center">
              <Typography>Product Received</Typography>
              <Checkbox
                checked={productReceived}
                onChange={(e) => setProductReceived(e.target.checked)}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography>Cashback Received</Typography>
              <Checkbox
                checked={cashbackReceived}
                onChange={(e) => setCashbackReceived(e.target.checked)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </Dialog>
      );
    },
    onCreatingRowSave: async ({ table, values }: { table: any; values: any }) => {
      const userId = currentUser?.id ? Number(currentUser.id) : 0;
    
      if (!setNum || !setName) {
        alert('Please enter a valid set number');
        return;
      }
    
      try {
        const newRowData = {
          set_num: setNum,
          quantity: quantity ?? 1,
          price_paid: pricePaid || 0,
          taxes_fees: taxesFees || 0, // Include taxes & fees
          date_acquired: dateAcquired ? dateAcquired.toString() : null, // Pass the date as a string without any timezone conversion
          collection: collection || '',
          location: location || '',
          condition: condition || '',
          vendor: vendor || '', // Include vendor
          product_received: productReceived || false, // Include product received
          cb_received: cashbackReceived || false, // Include cashback received
          cb_source: cashbackSource || '', // Include cashback source
          cb_percent: cashbackPercent || 0, // Include cashback percent
          order_number: orderNumber || '', // Include order number
          notes: notes || '', // Include notes
          user_id: userId,
        };
    
        await createInventoryRow(newRowData, selectedSet, userId);
        await refetch();
        
        table.setCreatingRow(null);
      } catch (error) {
        console.error('Error creating inventory row:', error);
      }
    },
    

    onEditingRowSave: async ({ row, table }) => {
      setSavingRowId(row.original.id ?? 0); // Set the row ID being saved
      try {
        // Prepare updated row data
        const updatedValues = row.getAllCells().reduce((acc, cell) => {
          acc[cell.column.id] = cell.getValue();
          return acc;
        }, {} as Record<string, any>);
    
        const updatedRowData: UserSetInv = {
          ...row.original,
          price_paid: Number(updatedValues.price_paid ?? 0),
          taxes_fees: Number(updatedValues.taxes_fees ?? 0), // Include taxes & fees
          date_acquired: updatedValues.date_acquired || null,
          collection: updatedValues.collection || '',
          location: updatedValues.location || '',
          condition: updatedValues.condition || '',
          vendor: updatedValues.vendor || '', // Include vendor
          product_received: !!updatedValues.product_received, // Include product received
          cb_received: !!updatedValues.cb_received, // Include cashback received
          cb_source: updatedValues.cb_source || '', // Include cashback source
          cb_percent: Number(updatedValues.cb_percent ?? 0), // Include cashback percent
          order_number: updatedValues.order_number || '', // Include order number
          notes: updatedValues.notes || '',
          user_id: 0
        };
    
        await updateInventoryRow(updatedRowData); // Call the update API
        refetch(); // Refetch inventory data
        table.setEditingRow(null); // Exit editing mode
      } catch (error) {
        console.error('Error updating row:', error);
      } finally {
        setSavingRowId(null); // Reset savingRowId
      }
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      rowSelection,
    },
    enablePagination: false,
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    }
  );
  
  const [savingRowId, setSavingRowId] = useState<number | null>(null);

  const handleResetFilters = () => {
    InventoryManagementTable.setColumnFilters([]); // Clear all column filters
    InventoryManagementTable.setGlobalFilter(''); // Optionally clear global filter as well
  };

  const handleDeleteSelected = async () => {
  
    const selectedRowIds = Object.keys(rowSelection); // Get the IDs of selected rows
  
    if (selectedRowIds.length === 0) {
      alert('No rows selected for deletion.');
      return;
    }
  
    if (
      !window.confirm(
        `Are you sure you want to delete ${selectedRowIds.length} selected row(s)?`
      )
    ) {
      return;
    }
  
    try {
      // Use Promise.all to send DELETE requests concurrently
      await Promise.all(
        selectedRowIds.map(async (id) => {
          try {
            await axiosInstance.delete('/users/sets/inventory', {
              withCredentials: true, // Ensures cookies are sent with the request
              data: { id }, // Axios uses `data` for the request body in DELETE requests
            });
          } catch (error) {
            if (axios.isAxiosError(error)) {
              console.error(`Failed to delete inventory row with ID: ${id}`, error.response?.data);
            } else {
              console.error(`Unexpected error deleting inventory row with ID: ${id}`, error);
            }
          }
        })
      );
    
      alert('Selected rows deleted successfully!');
      refetch(); // Refresh the table data
      setRowSelection({}); // Clear the selection
    } catch (error) {
      console.error('Error deleting selected rows:', error);
      alert('Failed to delete selected rows. Please try again.');
    }
  };
  

  
  
  const resetForm = () => {
    setSetNum('');
    setSetName('');
    setError('');
    setQuantity(1);
    setPricePaid(0);
    setTaxesFees(0);
    setDateAcquired(dayjs());
    setCollection('');
    setLocation('');
    setCondition('');
    setVendor('');
    setProductReceived(false);
    setCashbackReceived(false);
    setCashbackSource('');
    setCashbackPercent(0);
    setOrderNumber('');
    setNotes('');
  };

// Called from your React code to PATCH /users/sets/inventory
  // sending { id, quantity, price_paid, ... } in the JSON body
  const updateInventoryRow = async (updatedRow: any) => {
    try {
      const response = await axiosInstance.patch('/users/sets/inventory', {
        id: updatedRow.id,
        price_paid: updatedRow.price_paid ?? 0,
        taxes_fees: updatedRow.taxes_fees ?? 0,
        date_acquired: updatedRow.date_acquired || null,
        vendor: updatedRow.vendor || '',
        collection: updatedRow.collection || '',
        location: updatedRow.location || '',
        condition: updatedRow.condition || '',
        product_received: updatedRow.product_received ?? false,
        cb_received: updatedRow.cb_received ?? false,
        cb_source: updatedRow.cb_source || '',
        cb_percent: updatedRow.cb_percent ?? 0,
        order_number: updatedRow.order_number || '',
        notes: updatedRow.notes || '',
      }, {
        withCredentials: true, // Ensures cookies are sent with the request
      });
  
      return response.data; // e.g., { message: 'Inventory record updated', inventory }
    } catch (error) {
      console.error('Error updating inventory row:', error);
      throw new Error('Failed to update inventory row');
    }
  };
  
  // ✅ Refactored deleteInventoryRow function
  const deleteInventoryRow = async (inventoryId: number) => {
    try {
      const response = await axiosInstance.delete('/users/sets/inventory', {
        withCredentials: true, // Ensures cookies are sent with the request
        data: { id: inventoryId }, // Axios uses `data` for request body in DELETE requests
      });
  
      return response.data; // Return success response
    } catch (error) {
      console.error('Error deleting inventory row:', error);
      throw new Error('Failed to delete inventory row');
    }
  };

const openDeleteConfirmModal = (inventoryId: number) => {
  if (window.confirm('Are you sure you want to delete this item from the inventory?')) {
    deleteInventoryRow(inventoryId)
      .then(() => {
        console.log(`Deleted inventory row with ID: ${inventoryId}`);
        refetch(); // Trigger refetch of InventoryManagementTable data
      })
      .catch((error) => {
        console.error('Error deleting inventory row:', error);
      });
  }
};


  
  // //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  // const fetchMoreOnBottomReached = useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement) {
  //       const {scrollHeight, scrollTop, clientHeight} = containerRefElement
  //       console.log('Scroll Values:', {scrollHeight, scrollTop, clientHeight})
  //       if (
  //         scrollHeight - scrollTop - clientHeight < 200 &&
  //         !isFetching &&
  //         totalFetched < totalDBRowCount
  //       ) {
  //         console.log('Fetching next page...')
  //         fetchNextPage()
  //       }
  //     }
  //   },
  //   [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  // )

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      console.error(error)
    }
  }, [sorting, columnFilters, globalFilter])

  useEffect(() => {
    if (userSelectedSet) {
      setDebouncedValue(userSelectedSet); // Set the default value to userSelectedSet if it exists
      setSetNum(userSelectedSet); // Also update the setNum state
    }
  }, [userSelectedSet]); // Trigger when userSelectedSet changes

// Retrieve user ID from useAuth()
const { currentUser } = useAuth();
let userId: number | undefined;

// Convert currentUser?.id to a number if it's defined
if (currentUser?.id) {
  userId = Number(currentUser.id);

  if (isNaN(userId)) {
    console.error('Invalid user ID');
    userId = 0;  // Set a default value or handle the error appropriately
  }
} else {
  console.error('User ID is undefined');
}

const ImportModal: React.FC<{ open: boolean; onClose: () => void; onSubmit: (rows: any[]) => void }> = ({ open, onClose, onSubmit }) => {
  const [file, setFile] = useState<File | null>(null);
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const [previewRows, setPreviewRows] = useState<any[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      setFile(file);
  
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (!event.target?.result) return;
  
        if (file.type.includes('csv')) {
          Papa.parse(event.target.result as string, {
            header: true,
            complete: (result) => setPreviewRows(result.data),
          });
        } else {
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(event.target.result as ArrayBuffer);
  
          const worksheet = workbook.worksheets[0];
          const rows: { [key: string]: string | number | null }[] = [];
          worksheet.eachRow((row, rowNumber) => {
            const rowData: Record<string, string> = {};
            row.eachCell((cell, colNumber) => {
              const header = worksheet.getRow(1).getCell(colNumber).value;
              if (header) {
                rowData[header.toString()] = cell.text;
              }
            });
            if (rowNumber > 1) rows.push(rowData); // Skip header row
          });
  
          setPreviewRows(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleMappingChange = (fileColumn: string, dbColumn: string) => {
    setMapping((prev) => ({ ...prev, [fileColumn]: dbColumn }));
  };

  const handleSubmit = () => {
    const mappedRows = previewRows.map((row) =>
      Object.keys(row).reduce((acc: Record<string, string>, col) => {
        if (mapping[col]) {
          acc[mapping[col]] = row[col]; // Only map if there's a valid mapping
        }
        return acc;
      }, {})
    );
    onSubmit(mappedRows);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Import Inventory</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Upload your CSV file and map the bolded column headers to the database using the dropdowns beneath each one. <b>Set number and quantity are the only required fields. If your CSV lists each set on a row and no quantity column exists, you must create a quantity column and specify "1" for each row. Dates should be in MM/DD/YYYY format.</b> You don't need to map any set specific details such as set name or piece count as these will pull in automatically from the database. If any rows fail to import, a file will automatically be downloaded giving you the rows that failed to import.</Typography>
        <br />
        <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileChange} />
        {previewRows.length > 0 && (
          <table>
            <thead>
              <tr>
                {Object.keys(previewRows[0]).map((col) => (
                  <th key={col}>
                    {col}
                    <Select
                      value={mapping[col] || ''}
                      onChange={(e) => handleMappingChange(col, e.target.value)}
                    >
                      <MenuItem value="">Ignore</MenuItem>
                      <MenuItem value="set_num">Set Number</MenuItem>
                      <MenuItem value="quantity">Quantity</MenuItem>
                      <MenuItem value="price_paid">Price Paid</MenuItem>
                      <MenuItem value="taxes_fees">Taxes & Fees</MenuItem>
                      <MenuItem value="condition">Condition</MenuItem>
                      <MenuItem value="collection">Collection</MenuItem>
                      <MenuItem value="date_acquired">Date Acquired</MenuItem>
                      <MenuItem value="vendor">Vendor</MenuItem>
                      <MenuItem value="location">Location</MenuItem>
                      <MenuItem value="product_received">Product Received</MenuItem>
                      <MenuItem value="cb_received">Cashback Received</MenuItem>
                      <MenuItem value="cb_source">Cashback Source</MenuItem>
                      <MenuItem value="cb_percent">Cashback Percent</MenuItem>
                      <MenuItem value="order_number">Order Number</MenuItem>
                      <MenuItem value="notes">Notes</MenuItem>
                    </Select>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {previewRows.slice(0, 10).map((row, i) => (
                <tr key={i}>
                  {Object.values(row).map((val, j) => (
                    <td key={j}>{String(val)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );

};

const SalesOrderModal: React.FC<SalesOrderModalProps> = ({ open, onClose, selectedRows, onSuccess }) => {
  const [salesOrderNumber, setSalesOrderNumber] = useState('');
  const [soldPrices, setSoldPrices] = useState<Record<number, number>>({});
  const [soldDate, setSoldDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [shippingCost, setShippingCost] = useState<number>(0);
  const [sellerFees, setSellerFees] = useState<number>(0);
  const [taxesCollected, setTaxesCollected] = useState<number>(0);
  const [salesPlatform, setSalesPlatform] = useState('');
  const [salesOrderNotes, setSalesOrderNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle sold price change for each row
  const handleSoldPriceChange = (id: number, value: number) => {
    setSoldPrices((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!soldDate) {
      setError('Sold Date is required.');
      return;
    }
  
    // Prepare data for submission
    const salesOrderItems = selectedRows.map((row) => ({
      inventory_id: row.id,
      sold_price: soldPrices[row.id] || 0, // Use the entered Sold Price
    }));
  
    // Send data to your API
// Send data to your API
try {
  setIsSubmitting(true);

  // ✅ Send the sales order along with sales items in a single request using axiosInstance
  const response = await axiosInstance.post('/users/sales_order', {
    sales_order_number: salesOrderNumber,
    sold_date: soldDate.toISOString(),
    shipping_cost: shippingCost,
    seller_fees: sellerFees,
    taxes_collected: taxesCollected,
    sales_platform: salesPlatform,
    sales_order_notes: salesOrderNotes,
    sales_items: salesOrderItems, // ✅ Include the sales items
  }, {
    withCredentials: true, // Ensures cookies are sent with the request
  });

  // Handle success
  onSuccess(); // Call the success callback
  setIsSubmitting(false);
  onClose(); // Close the modal
} catch (error) {
  console.error('Error creating sales order:', error);
  
  if (axios.isAxiosError(error) && error.response) {
    setError(error.response.data.message || 'An error occurred. Please try again.');
  } else {
    setError('An error occurred. Please try again.');
  }
  
  setIsSubmitting(false);
}

  };

  // const resetForm = () => {
  //   setSalesOrderNumber('');
  //   setSoldDate(dayjs());
  //   setSoldPrice(0);
  //   setShippingCost(0);
  //   setSellerFees(0);
  //   setTaxesCollected(0);
  //   setSalesPlatform('');
  //   setSalesOrderNotes('');
  //   setError('');
  // };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Sales Order</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left Column: Set Table */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Selected Items</Typography>
            <TableContainer component={Paper} style={{ maxHeight: 400, overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Set Number</TableCell>
                    <TableCell>Set Name</TableCell>
                    <TableCell>Sold Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.set_num}</TableCell>
                      <TableCell>{row.set?.name || row.name}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={soldPrices[row.id] || ''}
                          onChange={(e) => handleSoldPriceChange(row.id, parseFloat(e.target.value))}
                          fullWidth
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Right Column: Sales Order Fields */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Sales Order Number"
              value={salesOrderNumber}
              onChange={(e) => setSalesOrderNumber(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Sold Date"
                value={soldDate}
                onChange={(newValue) => setSoldDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: 'normal',
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
            <TextField
              label="Shipping Cost"
              type="number"
              value={shippingCost}
              onChange={(e) => setShippingCost(parseFloat(e.target.value))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Seller Fees"
              type="number"
              value={sellerFees}
              onChange={(e) => setSellerFees(parseFloat(e.target.value))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Taxes Collected"
              type="number"
              value={taxesCollected}
              onChange={(e) => setTaxesCollected(parseFloat(e.target.value))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sales Platform"
              value={salesPlatform}
              onChange={(e) => setSalesPlatform(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sales Order Notes"
              value={salesOrderNotes}
              onChange={(e) => setSalesOrderNotes(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={3}
            />
            {error && <Typography color="error">{error}</Typography>}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
    );
  };

const [importModalOpen, setImportModalOpen] = useState(false);
const [salesOrderModalOpen, setSalesOrderModalOpen] = useState(false);

const handleImportSubmit = async (rows: any[]) => {

  const validRows: any[] = [];
  const invalidRows: any[] = [];

  // Validate and preprocess rows
  for (const row of rows) {
    // Skip completely blank rows
    if (Object.values(row).every((value) => value === null || value === undefined || value === '')) {
      continue;
    }

    // Ensure booleans are treated correctly
    row.product_received = row.product_received === 'TRUE' ? true : false; // Null or invalid becomes false
    row.cb_received = row.cb_received === 'TRUE' ? true : false; // Null or invalid becomes false

    // Clean and parse currency fields
    row.price_paid = parseFloat(row.price_paid?.replace(/[^0-9.-]+/g, '') || '0');
    row.taxes_fees = parseFloat(row.taxes_fees?.replace(/[^0-9.-]+/g, '') || '0');
    // // Good: parse once, store as "YYYY-MM-DD"
    // const parsed = dayjs(row.date_acquired, 'MM/DD/YYYY', true);
    // row.date_acquired = parsed.isValid() 
    //   ? parsed.format('YYYY-MM-DD') 
    //   : null;

    // Validate required fields
    if (!row.set_num) {
      invalidRows.push({ ...row, error: 'Set number missing' });
      continue;
    }

    // Add "-1" suffix to set numbers if missing
    row.set_num = row.set_num.includes('-') ? row.set_num : `${row.set_num}-1`;

    // Push valid rows into the validRows array
    validRows.push(row);
  }

  try {
    // Make API calls in batches to avoid server overload
    const batchSize = 10; // Adjust as necessary
    for (let i = 0; i < validRows.length; i += batchSize) {
      const batch = validRows.slice(i, i + batchSize);

      // Use `Promise.all` to send batch requests concurrently
      await Promise.all(
        batch.map(async (inventory) => {
          try {
            await axiosInstance.post('/users/sets/inventory', inventory, {
              withCredentials: true, // Ensures cookies are sent with the request
            });
          } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              console.error(`Failed to import inventory for set_num ${inventory.set_num}:`, error.response.data);
              invalidRows.push({ ...inventory, error: error.response.data.message || 'Unknown error' });
            } else {
              console.error(`Unexpected error for set_num ${inventory.set_num}:`, error);
              invalidRows.push({ ...inventory, error: 'Unexpected error' });
            }
          }
        })
      );
    }

    alert('Inventory imported successfully!');
    refetch(); // Refresh the inventory table after import
  } catch (error) {
    console.error('Error importing inventory:', error);
    alert('Failed to import inventory. Please try again.');
  }

  // Generate a CSV for invalid rows (if any)
  if (invalidRows.length > 0) {
    const csv = Papa.unparse(invalidRows);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'invalid_rows.csv';
    link.click();
  }

  setImportModalOpen(false); // Close the modal after submission
};


const tableInvContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowInvVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) 

// Define the columns for the user inventory table
const inventoryColumns = useMemo<MRT_ColumnDef<UserSetInv>[]>(() => [
  {
    accessorKey: 'set_num',
    header: 'Set Number',
    enableEditing: false,  // Make 'Set Number' non-editable
    size: 100,
  },
  // {
  //   accessorKey: 'quantity',
  //   header: 'Quantity',
  //   enableEditing: true,
  //   size: 100,
  //   // Add editing props for the quantity field with typed 'cell'
  //   muiEditTextFieldProps: ({ cell }: { cell: MRT_Cell<UserSetInv> }) => ({
  //     type: 'number',  // Set input type to number
  //     inputProps: { min: 1 },  // Set a minimum value of 1
  //   }),
  // },
  {
    accessorKey: 'price_paid',
    header: 'Price Paid',
    Cell: ({ cell }) => {
      const value = Number(cell.getValue<number>());
      return !isNaN(value) ? `$${value.toFixed(2)}` : 'N/A';
    },
    size: 100,
    enableSorting: true,
    muiTableBodyCellEditTextFieldProps: {
      type: 'number',
    },
  },
  {
    accessorKey: 'date_acquired',
    header: 'Date Acquired',
    Cell: ({ cell }) => {
      const rawDateString = cell.getValue<string>()
      // Return 'N/A' if nullish, else display the exact string from DB
      return rawDateString ?? 'N/A'
    },
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      type: 'date',
    },
  },
  {
    accessorKey: 'collection',
    header: 'Collection',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter collection...',
    },
  },
  {
    accessorKey: 'location',
    header: 'Location',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter location...',
    },
  },
  {
    accessorKey: 'condition',
    header: 'Condition',
    size: 150,
    muiTableBodyCellEditTextFieldProps: {
      placeholder: 'Enter condition...',
    },
  },
], []);




const createInventoryRow = async (newRow: any, selectedSet: any, userId: number) => {
  try {
    // Set a default value of 1 for quantity if it's not provided
    const quantity = newRow?.quantity ?? 1;

    // Ensure the set_num has a "-1" suffix if no "-#" is present
    let setNum = newRow?.set_num || '';
    if (!setNum.match(/-\d+$/)) {
      setNum += '-1'; // Append "-1" if no suffix is present
    }

    // Construct API request to create a new inventory row using axiosInstance
    const response = await axiosInstance.post(
      '/users/sets/inventory',
      {
        userId,
        set_num: setNum,
        quantity,
        price_paid: newRow?.price_paid || 0,
        taxes_fees: newRow?.taxes_fees || 0,
        date_acquired: newRow?.date_acquired,
        vendor: newRow?.vendor || '',
        collection: newRow?.collection || '',
        location: newRow?.location || '',
        condition: newRow?.condition || '',
        product_received: newRow?.product_received || false,
        cb_received: newRow?.cb_received || false,
        cb_source: newRow?.cb_source || '',
        cb_percent: newRow?.cb_percent || 0,
        order_number: newRow?.order_number || '',
        notes: newRow?.notes || '',
      },
      {
        withCredentials: true, // Ensures cookies are sent with the request
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error('Failed to create inventory row:', error.response.data);
      throw new Error(error.response.data.message || 'Failed to create inventory row');
    } else {
      console.error('Unexpected error:', error);
      throw new Error('Unexpected error occurred. Please try again.');
    }
  }
};

// Return loading or error states after hooks
if (isLoading) {
  return (
    <Typography variant="h6" align="center" style={{ margin: '20px 0' }}>
      Loading data...
    </Typography>
  );
}

if (isError) {
  return (
    <Typography variant="h6" color="error" align="center" style={{ margin: '20px 0' }}>
      Error loading data. Please try again. If the issue persists, please try logging out and log back in.
    </Typography>
  );
}

  return (
    <div className={`card ${className} w-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 w-100'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Inventory Management - Sets</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
          {InventoryManagementTable.getRowModel()
    .rows.reduce((total, row) => {
      const item = row.original as CombinedSets;
      return total + (item.quantity ?? 0);
    }, 0)
    .toLocaleString()} Total Set(s) in Selected Inventory |
  Current Value: $
{InventoryManagementTable.getRowModel()
  .rows.reduce((total, row) => {
    const item = row.original as CombinedSets;

    // Extract prices
    const amazonPrice = item.amazon_price || 0;
    const blLowestNewPrice = item.bl_lowest_new_price || 0;

    // Apply conditional logic to get the lesser of the two prices
    const itemValue =
      amazonPrice > 0 && blLowestNewPrice > 0
        ? Math.min(amazonPrice, blLowestNewPrice)
        : amazonPrice > 0
        ? amazonPrice
        : blLowestNewPrice;

    return total + itemValue;
  }, 0)
  .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} |
  Cost: $
  {InventoryManagementTable.getRowModel()
    .rows.reduce((total, row) => {
      const item = row.original as CombinedSets;
      // Calculate cost as price_paid + taxes_fees * quantity
      const cost = ((item.price_paid ?? 0) + (item.taxes_fees ?? 0)) * (item.quantity ?? 1);
      return total + cost;
    }, 0)
    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} |
  Appreciation:&nbsp;
  {(() => {
    const visibleRows = InventoryManagementTable.getRowModel().rows.map(row => row.original as CombinedSets);

    const totalCost = visibleRows.reduce((total, item) => {
      // Adjust totalCost to include taxes_fees
      const cost = ((item.price_paid ?? 0) + (item.taxes_fees ?? 0)) * (item.quantity ?? 1);
      return total + cost;
    }, 0);

    const totalCurrentValue = visibleRows.reduce((total, item) => {
      // Extract prices
      const amazonPrice = item.amazon_price || 0;
      const blLowestNewPrice = item.bl_lowest_new_price || 0;
    
      // Apply conditional logic to get the lesser of the two prices
      const itemPrice =
        amazonPrice > 0 && blLowestNewPrice > 0
          ? Math.min(amazonPrice, blLowestNewPrice)
          : amazonPrice > 0
          ? amazonPrice
          : blLowestNewPrice;
    
      // Multiply by quantity and add to total
      return total + (itemPrice * (item.quantity ?? 1));
    }, 0);

    const appreciation =
      totalCost > 0
        ? ((totalCurrentValue - totalCost) / totalCost) * 100
        : 0;

    return `${appreciation.toFixed(2)}%`;
  })()}
            </span>
        </h3>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Set Inventory
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 w-100'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <MaterialReactTable
            table={InventoryManagementTable}
          />
          {/* Import Modal */}
        <ImportModal
          open={importModalOpen}
          onClose={() => setImportModalOpen(false)} // Close modal
          onSubmit={handleImportSubmit} // Handle the import logic
        />
        {/* Sales Order Modal */}
        <SalesOrderModal
          open={salesOrderModalOpen}
          onClose={() => setSalesOrderModalOpen(false)}
          selectedRows={InventoryManagementTable.getSelectedRowModel().rows.map(row => row.original as CombinedSets)}
          onSuccess={() => {
            setSalesOrderModalOpen(false);
            refetch();
            setRowSelection({});
            alert("Sales order created successfully!");
          }}
        />
{/* Modal */}
<div className="modal bg-body fade" tabIndex={-1} id="kt_modal_2">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content shadow-none">
      <div className="modal-header" style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
      <h5 className="modal-title">
        {selectedSet ? (
            <>
              <span style={{ marginRight: '10px' }}>
                {`${selectedSet.set_num}: ${selectedSet.name}`}
              </span>
                <img 
                    src={selectedSet.img_url} 
                    alt={`${selectedSet.name} thumbnail`} 
                    style={{ height: '50px', marginRight: '10px' }} // You can adjust the style as needed
                />
            </>
        ) : (
            "Set Details"
        )}
    </h5><span style={{ marginRight: '30px' }}>
        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close">
          <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
          {/* <i className="ki-duotone ki-cross fs-1">
          <KTIcon iconName='cross' className='fs-1' />
          </i> */}
        </div></span>
      </div>
      <div className="modal-body">
  <div className="row g-5 align-items-stretch">
    {/* Set Overview Column */}
    <div className="col-lg-3 d-flex flex-column">
      <div className="card card-custom card-stretch shadow mb-5 d-flex flex-column">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Set Overview</h3>
          {selectedSet && getRetirementImage() && (
            <img 
              src={getRetirementImage() || ''}  // Ensure src is always a string
              alt="Retirement status" 
              style={{ height: '30px', marginLeft: 'auto' }}  // Adjust the height as needed
            />
          )}
        </div>
        <div className="card-body py-2">
          <table className="table table-row-dashed table-row-gray-300 gy-2">
            <tbody>
              {/* Set overview table content */}
              <tr>
                <td>Set Number</td>
                <td>{selectedSet?.set_num ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedSet?.name ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Theme</td>
                <td>{selectedSet?.theme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Subtheme</td>
                <td>{selectedSet?.subtheme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Pieces</td>
                <td>{selectedSet?.num_parts != null ? selectedSet.num_parts.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Minifigures</td>
                <td>{selectedSet?.minifig_count != null ? selectedSet.minifig_count.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Release Date</td>
                <td>{selectedSet?.date_released ? selectedSet.date_released.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Retirement Date</td>
                <td>{selectedSet?.date_retired ? selectedSet.date_retired.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Dimensions (I)</td>
                <td>
                  {selectedSet && selectedSet.dim_length_imperial != null && selectedSet.dim_width_imperial != null && selectedSet.dim_height_imperial != null
                    ? `${selectedSet.dim_length_imperial} x ${selectedSet.dim_width_imperial} x ${selectedSet.dim_height_imperial} in`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (I)</td>
                <td>
                  {selectedSet?.weight_imperial != null
                    ? `${Math.floor(selectedSet.weight_imperial)} lb ${Math.round((selectedSet.weight_imperial % 1) * 16)} oz`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Dimensions (M)</td>
                <td>
                  {selectedSet?.dim_length_metric != null && selectedSet.dim_width_metric != null && selectedSet.dim_height_metric != null
                    ? `${selectedSet.dim_length_metric} x ${selectedSet.dim_width_metric} x ${selectedSet.dim_height_metric} cm`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (M)</td>
                <td>
                  {selectedSet?.weight_metric != null
                    ? `${selectedSet.weight_metric} g`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>MSRP (USD)</td>
                <td>{selectedSet?.rrp_usd != null ? `$${selectedSet.rrp_usd}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (GBP)</td>
                <td>{selectedSet?.rrp_gbp != null ? `£${selectedSet.rrp_gbp}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (CAD)</td>
                <td>{selectedSet?.rrp_cad != null ? `C$${selectedSet.rrp_cad}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (EUR)</td>
                <td>{selectedSet?.rrp_eur != null ? `€${selectedSet.rrp_eur}` : "N/A"}</td>
              </tr>
              <tr>
                <td>UPC Barcode</td>
                <td>{selectedSet?.upc_barcode ?? "N/A"}</td>
              </tr>
              <tr>
                <td>EAN Barcode</td>
                <td>{selectedSet?.ean_barcode ?? "N/A"}</td>
              </tr>
              {/* <tr>
                <td>Item Number</td>
                <td>{selectedSet?.item_number ?? "N/A"}</td>
              </tr>
              <tr>
                <td>BOID</td>
                <td>{selectedSet?.boid ?? "N/A"}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>

    {/* Minifigures and other sections */}
    <div className="col-lg-9 d-flex flex-column">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-body">
              {selectedSet && galleryImages.length > 0 ? (
                <ImageGallery 
                  items={galleryImages} 
                  showNav={false}
                  thumbnailPosition="right"
                />
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Metrics - Coming soon!</h3>
            </div>
          </div>
        </div>
      </div>
                
      <div className="row g-5">
        <div className="col-lg-12">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Minifigures</h3>
            </div>
            <div className="card-body" style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {minifigures.length > 0 ? (
                <table className="table table-row-dashed table-row-gray-300 gy-2">
                  <thead>
                    <tr>
                      <th>Thumbnail</th>
                      <th>Quantity</th>
                      <th>Minifigure</th>
                      <th>Bricklink #</th>
                      <th>Name</th>
                      <th>Pieces</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minifigures.map((minifig) => (
                      <tr key={minifig.fig_num}>
                        <td><img src={minifig.img_url} alt={minifig.name} height={50} /></td>
                        <td>{minifig.quantity}</td>
                        <td>{minifig.fig_num}</td>
                        <td>{minifig.bricklink_num}</td>
                        <td>{minifig.name}</td>
                        <td>{minifig.num_parts}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No minifigures found for this set.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
      </div>
      </div>
      </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const queryClient = new QueryClient()

const InventorySetsTable: React.FC<CatalogSetsTableProps> = () => (
  <QueryClientProvider client={queryClient}>
    <InventoryManagementTable />
  </QueryClientProvider>
)

export {InventoryManagementTable}
export {InventorySetsTable}


function setTableData(arg0: UserSetInv[]) {
  throw new Error('Function not implemented.')
}