/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import { SidebarMenuItem } from '../sidebar/sidebar-menu/SidebarMenuItem'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        
      </div>
      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <SidebarMenuItem
            to='/terms-of-use'
            title='Terms of Use' 
          />
        </li>
        <li className='menu-item'>
          <SidebarMenuItem
            to='/privacy-policy'
            title='Privacy Policy' 
          />
        </li>
        <li className='menu-item'>
          <SidebarMenuItem
            to='/affiliate-disclosures'
            title='Affiliate Disclosures' 
          />
        </li>


        {/* <li className='menu-item'>
          <a
            href='https://www.studfinder.app/get-pro'
            target='_blank'
            className='menu-link px-2'
          >
            Purchase
          </a>
        </li> */}
      </ul>
      <div className='text-dark talign-right order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          | &copy;{new Date().getFullYear().toString()}&nbsp;
        
          StudFinder.app |
          </span>
        <span className='text-muted fw-semibold me-1'>LEGO® is a trademark of the LEGO Group of companies which does not sponsor, authorize or endorse this site.</span>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
