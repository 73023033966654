import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Logout } from '../Logout';

// Flag to track if a refresh request is already in progress
let isRefreshing = false;

// Queue to hold failed requests while the token is being refreshed
let failedQueue: any[] = [];

// Function to process the queue of failed requests
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

export async function setupAxios(axiosInstance: any) {
  // Set default headers for axios
  axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  axiosInstance.defaults.withCredentials = true; // ✅ Ensure cookies are sent with every request

  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // No need to set Authorization header as cookies are automatically sent with withCredentials
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  // Response interceptor to handle session expiration
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (err: any) => {
      const originalRequest = err.config;

      // ✅ Handle 401 errors
      if (err.response && err.response.status === 401 && !originalRequest._retry) {
        // Mark the request as a retry to avoid infinite loop
        originalRequest._retry = true;

        // ✅ Check if a refresh request is already in progress
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              // Retry the original request with the new token
              return axiosInstance(originalRequest);
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }

        isRefreshing = true;

        try {
          // ✅ Attempt to refresh the token
          const refreshResponse = await axiosInstance.post(
            `${process.env.REACT_APP_API_URL}/auth/refresh`,
            {},
            { withCredentials: true }
          );

          if (refreshResponse.data.accessToken) {
            console.log('Access token refreshed.');

            // ✅ Process the queue with the new token
            processQueue(null, refreshResponse.data.accessToken);

            // Retry the original request with the new token
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);

          // ✅ Clear failed requests queue and logout the user
          processQueue(refreshError, null);
          removeAuth(); // Clear cookies and local storage
          Logout(); // Redirect to login
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(err);
    }
  );
}

// ✅ Function to set the access token in cookies
export const setAuth = (auth: { accessToken: string; refreshToken?: string }) => {
  try {
    document.cookie = `accessToken=${auth.accessToken}; path=/; secure; SameSite=Strict`;
    if (auth.refreshToken) {
      document.cookie = `refreshToken=${auth.refreshToken}; path=/; secure; SameSite=Strict`;
    }
    console.log('Authentication set successfully.');
  } catch (error) {
    console.error('Error setting authentication:', error);
  }
};

// ✅ Function to remove auth cookies
export const removeAuth = () => {
  try {
    document.cookie = 'accessToken=; Max-Age=0; path=/;';
    document.cookie = 'refreshToken=; Max-Age=0; path=/;';
    console.log('Authentication removed.');
  } catch (error) {
    console.error('Error removing authentication:', error);
  }
};
