import React, {UIEvent, useMemo, useEffect, useState, useRef, useCallback} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  MaterialReactTable,
  MRT_Cell,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table'
import {Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {QueryClient, QueryClientProvider, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {Box, Typography, GlobalStyles, CircularProgress} from '@mui/material'
//Date Picker Imports
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {DatePickerInput, DatesRangeValue} from '@mantine/dates'
import SetLineChart from '../charts/SetLineChart'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useAuth } from '../../../../app/modules/auth'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {InventoryManagementTable} from './InventorySetsTable';
import axios from 'axios'
import axiosInstance from '../../../../app/modules/auth/core/axiosInstance'

interface CatalogSetsTableProps {
  className?: string // Make it optional
}

interface SetsCatalogProps {
  className?: string // Make it optional
}

type SetsApiResponse = {
  data: Array<Sets>
  meta: {
    totalRowCount: number
  }
}

type Sets = {
  set_num: string
  name: string
  theme: string
  subtheme: string
  upc_barcode: string
  ean_barcode: string
  num_parts: number
  item_number: string
  boid: string
  dim_length_imperial: number
  dim_width_imperial: number
  dim_height_imperial: number
  weight_imperial: number
  dim_length_metric: number
  dim_width_metric: number
  dim_height_metric: number
  weight_metric: number
  date_released: Date
  date_retired: Date
  rrp_usd: number
  rrp_gbp: number
  rrp_cad: number
  rrp_eur: number
  img_url: string
  minifig_count: number
  galleryImages: string[];
}

type Minifigures = {
  quantity: number
  img_url: string
  fig_num: string
  bricklink_num: string
  name: string
  num_parts: number
}

type UserSetInv = {
  id?: number
  user_id: number
  set_num: string
  quantity: number
  price_paid: number
  taxes_fees: number
  date_acquired: Date | null
  vendor: string
  collection: string
  location: string
  condition: string
}

interface SelectedSetSubset {
  set_num: string;
  name: string;
  upc_barcode: string;
  date_retired: Date;
}

interface ImageGalleryItem {
  original: string;
  thumbnail: string;
  // ... any other properties you want to include
}

const AccordionManager = ({ selectedSet }: { selectedSet: SelectedSetSubset | null }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    const accordionBody = document.getElementById('kt_accordion_1_body_1');

    if (accordionBody) {
      const handleShow = () => setIsAccordionOpen(true);
      const handleHide = () => setIsAccordionOpen(false);

      accordionBody.addEventListener('show.bs.collapse', handleShow);
      accordionBody.addEventListener('hide.bs.collapse', handleHide);

      return () => {
        accordionBody.removeEventListener('show.bs.collapse', handleShow);
        accordionBody.removeEventListener('hide.bs.collapse', handleHide);
      };
    }
  }, []);

  return (
    <div data-bs-parent='#kt_accordion_1'>
      <div className='accordion-body'>
        {isAccordionOpen && (
          <SetLineChart 
            upc_barcode={selectedSet ? selectedSet.upc_barcode : ''} 
            type="BUY_BOX_SHIPPING"
            date_retired={selectedSet ? selectedSet.date_retired : new Date()} // passing the date_retired value
          />
        )}
      </div>
    </div>
  );
}

const SetsCatalog: React.FC<SetsCatalogProps> = ({className}) => {

  const [selectedSet, setSelectedSet] = useState<{ set_num: string, name: string, theme: string, subtheme: string, upc_barcode: string, ean_barcode: string,  img_url: string, num_parts: number, item_number: string, boid: string, dim_length_imperial: number, dim_width_imperial: number, dim_height_imperial: number, weight_imperial: number, dim_length_metric: number, dim_width_metric: number, dim_height_metric: number, weight_metric: number, minifig_count: number, date_released: Date, date_retired: Date, rrp_usd: number, rrp_gbp: number, rrp_cad: number, rrp_eur: number, } | null>(null);
  const [galleryImages, setGalleryImages] = useState<ImageGalleryItem[]>([]);

  const retiringSoonImageUrl = 'https://img.studfinder.app/RetiringSoon.png';
  const retiredImageUrl = 'https://img.studfinder.app/Retired.png';

  const [minifigures, setMinifigures] = useState<Minifigures[]>([]); // State to store minifigures

  const handleSetClick = async (set: Sets) => {
    try {
      // Push a history state when the modal opens
      window.history.pushState({ modalOpen: true }, '', `#-${set.set_num}`);
  
      // Clear previous data
      setSelectedSet(null);
      setGalleryImages([]);
      setMinifigures([]);
  
      // Make parallel requests using axiosInstance
      const [imagesResponse, minifigsResponse] = await Promise.all([
        axiosInstance.get(`/sets/${set.set_num}/images`, { withCredentials: true }),
        axiosInstance.get(`/minifigures/${set.set_num}`, { withCredentials: true }),
      ]);
  
      // Extract image data
      const images = imagesResponse.data.images;
      const minifigData = minifigsResponse.data;
  
      // Format the gallery images
      const formattedGalleryImages: ImageGalleryItem[] = images.map((url: string) => ({
        original: url,
        thumbnail: url,
      }));
  
      // Set state with the fetched data
      setMinifigures(minifigData.data); // Assuming the API returns the minifigures array in data
      setGalleryImages(formattedGalleryImages);
  
      // Set the selected set with all the details
      setSelectedSet({
        set_num: set.set_num,
        name: set.name,
        theme: set.theme,
        subtheme: set.subtheme,
        upc_barcode: set.upc_barcode,
        ean_barcode: set.ean_barcode,
        num_parts: set.num_parts,
        item_number: set.item_number,
        boid: set.boid,
        dim_length_imperial: set.dim_length_imperial,
        dim_width_imperial: set.dim_width_imperial,
        dim_height_imperial: set.dim_height_imperial,
        weight_imperial: set.weight_imperial,
        dim_length_metric: set.dim_length_metric,
        dim_width_metric: set.dim_width_metric,
        dim_height_metric: set.dim_height_metric,
        weight_metric: set.weight_metric,
        rrp_usd: set.rrp_usd,
        rrp_gbp: set.rrp_gbp,
        rrp_cad: set.rrp_cad,
        rrp_eur: set.rrp_eur,
        minifig_count: set.minifig_count,
        img_url: images[0],
        date_released: new Date(set.date_released),
        date_retired: new Date(set.date_retired),
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error fetching set data:', error.response.data.message || error.message);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };
  

  useEffect(() => {
    const handlePopState = () => {
      const modalElement = document.getElementById('kt_modal_2');
  
      if (modalElement?.classList.contains('show')) {
        // Close the modal
        modalElement.classList.remove('show');
        modalElement.style.display = 'none';
  
        // Remove body classes and reset styles
        document.body.classList.remove('modal-open');
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('z-index');
  
        // Remove any remaining backdrop
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
  
        // Reset selected set and modal content
        setSelectedSet(null);
        setGalleryImages([]);
        setMinifigures([]);
  
        // 🛠 Force a re-render to ensure UI elements are fully interactive
        setTimeout(() => {
          document.body.click();
        }, 0);
      }
    };
  
    // Add event listener
    window.addEventListener('popstate', handlePopState);
  
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [selectedSet]);

  // Helper function to determine if a set is retiring soon or already retired
  const getRetirementImage = () => {
    if (!selectedSet || !selectedSet.date_retired) return null;

    const today = new Date();
    const retirementDate = new Date(selectedSet.date_retired);
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(today.getMonth() + 6);

    if (retirementDate < today) {
      return retiredImageUrl;
    } else if (retirementDate < sixMonthsFromNow) {
      return retiringSoonImageUrl;
    }

    return undefined;  // No image if not retiring soon or retired
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent Bootstrap from handling the event
  };

  const columns = useMemo<MRT_ColumnDef<Sets>[]>(
    () => [
      {
        accessorKey: 'thumbnail',
        enableClickToCopy: false,
        header: 'Thumbnail',
        size: 150,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          const setNum = row.original.set_num; // Use set number to construct image URL
          const imgUrl = `https://cdn.studfinder.app/images/sets/${setNum}%2F${setNum}_image1.jpg`; // Construct the image URL
        
          return (
            <div onClick={() => handleSetClick(row.original)}>
              {imgUrl ? (
                <img 
                  src={imgUrl} 
                  onError={(e) => (e.currentTarget.style.display = 'none')} // Hide the image if it fails to load
                  height={50} 
                  loading="lazy"  // Lazy load images
                  data-bs-toggle="modal" 
                  data-bs-target="#kt_modal_2"
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <div /> // Return empty div if there's no image
              )}
            </div>
          );
        },
      },      
      {
        accessorKey: 'set_num',
        header: 'Set Number',
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ cell, row }) => {
          const setNum = cell.getValue<string>()
          const modalId = `modal-${setNum}`;
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setNum}
            </div>
          )
        },
      },      
      {
        accessorKey: 'name',
        header: 'Set Name',
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ cell, row }) => {
          const setName = cell.getValue<string>()
          return (
            <div 
              data-bs-toggle="modal" 
              data-bs-target="#kt_modal_2" 
              onClick={() => handleSetClick(row.original)}
              style={{ cursor: 'pointer' }}
            >
              {setName}
            </div>
          )
        },
      },
      {
        accessorKey: 'theme',
        header: 'Theme',
        enableGlobalFilter: true,
        size: 120,
      },
      {
        accessorKey: 'rrp_usd',
        header: 'MSRP ($)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `$${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_gbp',
        header: 'MSRP (£)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `£${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_cad',
        header: 'MSRP (C$)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `C$${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'rrp_eur',
        header: 'MSRP (€)',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value != null ? `€${value}` : ''; // Return empty string if null
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorKey: 'num_parts',
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return value != null ? value.toLocaleString() : ''; // Add null check
        },
        header: 'Pieces',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 150,
        muiFilterSliderProps: {
          marks: true,
          max: 12000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
      {
        accessorKey: 'minifig_count',
        header: 'Minifigures',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        columnFilterDisplayMode: 'popover',
      },
      {
        accessorFn: (row) => new Date(row.date_released), // Convert to Date for sorting and filtering
        id: 'date_released',
        header: 'Released',
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => new Date(row.date_retired), // Convert to Date for sorting and filtering
        id: 'date_retired',
        header: 'Retired',
        // filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const date = cell.getValue<Date>();
          return date ? date.toLocaleDateString() : 'N/A'; // Add null check
        },
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        Filter: ({column}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInput
              type='range'
              clearable
              placeholder='Select Date Range'
              valueFormat='MM/DD/YY'
              onChange={(newValue: any) => {
                column.setFilterValue(newValue) // Set the filter value for this column
              }}
              value={column.getFilterValue() as DatesRangeValue}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  )
  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const [rowCount, setRowCount] = useState(0)
  const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
  useInfiniteQuery<SetsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters, // Refetch when columnFilters changes
      globalFilter, // Refetch when globalFilter changes
      pagination.pageIndex, // Refetch when pagination.pageIndex changes
      pagination.pageSize, // Refetch when pagination.pageSize changes
      sorting, // Refetch when sorting changes
    ],
    queryFn: async ({ pageParam = 0, signal }: { pageParam?: number; signal?: AbortSignal }) => {
      try {
        // Use axiosInstance to make the GET request with query parameters
        const response = await axiosInstance.get<SetsApiResponse>('/sets/', {
          withCredentials: true, // Ensures cookies are sent with the request
          params: {
            page: pageParam + 1, // +1 because pageParam starts from 0 for the first page
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            search: globalFilter ?? '',
            sort: JSON.stringify(sorting ?? []),
          },
          signal, // Supports request cancellation
        });

        console.log('Fetched Data:', response.data);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          console.error('Error fetching data:', error.response.data.message || error.message);
          throw new Error(error.response.data.message || 'Error fetching data');
        } else {
          console.error('Unexpected error:', error);
          throw new Error('Unexpected error occurred. Please try again.');
        }
      }
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleResetFilters = () => {
    SetsCatalogTable.setColumnFilters([]); // Clear all column filters
    SetsCatalogTable.setGlobalFilter(''); // Optionally clear global filter as well
  };

  const flatData = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data])
  console.log(data)
  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
  const totalFetched = flatData.length

  const SetsCatalogTable = useMaterialReactTable({
    columns,
    data: flatData,
    enableEditing: false,
    enableGlobalFilter: true,
    editDisplayMode: 'modal',
    columnFilterDisplayMode: 'subheader',
    enableClickToCopy: false,
    enableRowSelection: false,
    enableRowVirtualization: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableRowPinning: false,
    enableRowActions: false,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: {overscan: 10},   
    initialState: {
      showColumnFilters: true,
      columnOrder: ['thumbnail','set_num', 'name', 'theme', 'rrp_usd'],
      columnPinning: { left: ['thumbnail', 'set_num', 'name'] },
      columnVisibility: {
        rrp_gbp: false,
        rrp_cad: false,
        rrp_eur: false,
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
      },
      'mrt-row-numbers': {
        enableColumnOrdering: true, //turn on some features that are usually off
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
      <div>
          {/* <Typography>Pieces: {row.original.num_parts.toString()}</Typography> */}
          <Typography>Theme: {row.original.theme}</Typography>
        </div>
      </Box>),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {maxHeight: '400px'}, //give the table a max height
      onScroll: (
        event: UIEvent<HTMLDivElement> //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiToolbarAlertBannerProps: isError 
    ? {
        color: 'error',
        children: 'Error loading data',
      }
    : undefined,
    renderTopToolbarCustomActions:(table) => (
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            onClick={handleResetFilters} // Call the resetFilters function
          >
            Reset Filters
          </Button>
        </div>
    ),
    renderBottomToolbarCustomActions: () => (
      <Typography>
        Fetched {totalFetched} of {totalDBRowCount.toLocaleString()} total rows. Good boy! ˁ(⚆ᴥ⚆)ˀ
      </Typography>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
    enablePagination: false,
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    }
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const {scrollHeight, scrollTop, clientHeight} = containerRefElement
        console.log('Scroll Values:', {scrollHeight, scrollTop, clientHeight})
        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          console.log('Fetching next page...')
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  )

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      console.error(error)
    }
  }, [sorting, columnFilters, globalFilter])

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  useEffect(() => {
    if (selectedSet) {
      // Perform any actions needed when a new set is selected
      console.log('Selected Set Updated:', selectedSet);
    } else {
      // Clear modal content when no set is selected
      setGalleryImages([]);
      setMinifigures([]);
    }
  }, [selectedSet]);

const tableInvContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowInvVirtualizerInstanceRef =
    useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) 

// // Define the columns for the user inventory table
// const inventoryColumns = useMemo<MRT_ColumnDef<UserSetInv>[]>(() => [
//   {
//     accessorKey: 'set_num',
//     header: 'Set Number',
//     enableEditing: false,  // Make 'Set Number' non-editable
//     size: 100,
//   },
//   {
//     accessorKey: 'quantity',
//     header: 'Quantity',
//     enableEditing: true,
//     size: 100,
//     // Add editing props for the quantity field with typed 'cell'
//     muiEditTextFieldProps: ({ cell }: { cell: MRT_Cell<UserSetInv> }) => ({
//       type: 'number',  // Set input type to number
//       inputProps: { min: 1 },  // Set a minimum value of 1
//     }),
//     enableSorting: true,
//     enableColumnFilter: true,
//   },
//   {
//     accessorKey: 'price_paid',
//     header: 'Price Paid',
//     size: 100,
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: ({ cell }) => ({
//       type: 'number', // numeric input
//       inputProps: { min: 0 }, // enforce non-negative values
//     }),
//     Cell: ({ cell }) => {
//       const rawValue = cell.getValue(); // rawValue is of type `unknown`
  
//       // Narrowing the type of `rawValue` and ensuring a valid number
//       let numericValue: number;
//       if (typeof rawValue === 'number') {
//         numericValue = rawValue; // Already a number
//       } else if (typeof rawValue === 'string' && rawValue.trim() !== '') {
//         numericValue = parseFloat(rawValue); // Convert string to number
//       } else {
//         return ''; // Return empty string if the value is not a valid number or string
//       }
  
//       // Ensure numericValue is a valid number
//       if (isNaN(numericValue)) {
//         return ''; // Return empty string if the value is not a valid number
//       }
  
//       return `$${numericValue.toFixed(2)}`; // Format the number to 2 decimal places
//     },
//     filterVariant: 'range',
//     filterFn: 'betweenInclusive',
//     columnFilterDisplayMode: 'popover',
//   },
//   {
//     accessorKey: 'taxes_fees',
//     header: 'Taxes & Fees',
//     size: 100,
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: ({ cell }) => ({
//       type: 'number', // numeric input
//       inputProps: { min: 0 }, // enforce non-negative values
//     }),
//     Cell: ({ cell }) => {
//       const rawValue = cell.getValue(); // rawValue is of type `unknown`
  
//       // Narrowing the type of `rawValue` and ensuring a valid number
//       let numericValue: number;
//       if (typeof rawValue === 'number') {
//         numericValue = rawValue; // Already a number
//       } else if (typeof rawValue === 'string' && rawValue.trim() !== '') {
//         numericValue = parseFloat(rawValue); // Convert string to number
//       } else {
//         return ''; // Return empty string if the value is not a valid number or string
//       }
  
//       // Ensure numericValue is a valid number
//       if (isNaN(numericValue)) {
//         return ''; // Return empty string if the value is not a valid number
//       }
  
//       return `$${numericValue.toFixed(2)}`; // Format the number to 2 decimal places
//     },
//     filterVariant: 'range',
//     filterFn: 'betweenInclusive',
//     columnFilterDisplayMode: 'popover',
//   },
//   {
//     accessorKey: 'date_acquired',
//     header: 'Date Acquired',
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: {
//       type: 'date', // Enables date picker
//     },
//   },
//   {
//     accessorKey: 'vendor',
//     header: 'Vendor',
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: {
//       placeholder: 'Vendor',
//     },
//   },
//   {
//     accessorKey: 'collection',
//     header: 'Collection',
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: {
//       placeholder: 'Collection',
//     },
//   },
//   {
//     accessorKey: 'location',
//     header: 'Location',
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: {
//       placeholder: 'Location',
//     },
//   },
//   {
//     accessorKey: 'condition',
//     header: 'Condition',
//     enableSorting: true,
//     enableColumnFilter: true,
//     muiEditTextFieldProps: {
//       placeholder: 'Condition',
//     },
//   },
// ], []);

// const createInventoryRow = async (newRow: any, selectedSet: any, userId: number) => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     throw new Error('No token found');
//   }

//   const response = await fetch(`${process.env.REACT_APP_API_URL}/users/sets/inventory`, {
//     method: 'POST',
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       userId,
//       set_num: selectedSet?.set_num,
//       quantity: newRow.quantity ?? 1, // Add additional fields here
//       price_paid: newRow.price_paid,
//       taxes_fees: newRow.taxes_fees,
//       date_acquired: newRow.date_acquired,
//       vendor: newRow.vendor,
//       collection: newRow.collection,
//       location: newRow.location,
//       condition: newRow.condition,
//     }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to create inventory row');
//   }

//   return response.json();
// };

//   // Called from your React code to PATCH /users/sets/inventory
//   // sending { id, quantity, price_paid, ... } in the JSON body
//   const updateInventoryRow = async (updatedRow: any) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('No token found');
//     }

//     const response = await fetch(`${process.env.REACT_APP_API_URL}/users/sets/inventory`, {
//       method: 'PATCH',
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         // The row's primary key
//         id: updatedRow.id,

//         // Updated fields
//         quantity: updatedRow.quantity ?? 1,
//         price_paid: updatedRow.price_paid,
//         taxes_fees: updatedRow.taxes_fees,
//         date_acquired: updatedRow.date_acquired,
//         vendor: updatedRow.vendor,
//         collection: updatedRow.collection,
//         location: updatedRow.location,
//         condition: updatedRow.condition,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update inventory row');
//     }
//     return response.json(); // e.g. { message: 'Inventory record updated', inventory }
//   };

// const deleteInventoryRow = async (inventoryId: number) => {
//   const token = localStorage.getItem('token');
//   if (!token) {
//     throw new Error('No token found');
//   }

//   const response = await fetch(`${process.env.REACT_APP_API_URL}/users/sets/inventory`, {
//     method: 'DELETE',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ id: inventoryId }), // <-- pass the row id in JSON body
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete inventory row');
//   }

//   return response.json(); // e.g. { message: 'Inventory record deleted' }
// };



// const SetInvtableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
//   const SetInvrowVirtualizerInstanceRef =
//     useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
//   const [SetInvcolumnFilters, SetInvsetColumnFilters] = useState<MRT_ColumnFiltersState>([])
//   const [SetInvglobalFilter, SetInvsetGlobalFilter] = useState('')
//   const [SetInvsorting, SetInvsetSorting] = useState<MRT_SortingState>([])
//   const [SetInvpagination, SetInvsetPagination] = useState<MRT_PaginationState>({
//     pageIndex: 0,
//     pageSize: 20,
//   })

//   const { data: inventoryQueryData, refetch: refetchInventory } = useQuery(
//     ['userInventory', selectedSet?.set_num, userId, SetInvsorting, SetInvcolumnFilters],
//     async () => {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         throw new Error('No token found');
//       }
  
//       const fetchURL = new URL(
//         `${process.env.REACT_APP_API_URL}/users/sets/${selectedSet?.set_num}/inventory`
//       );
  
//       // Add sorting and filtering parameters
//       fetchURL.searchParams.set('sort', JSON.stringify(SetInvsorting));
//       fetchURL.searchParams.set('filters', JSON.stringify(SetInvcolumnFilters));
  
//       const response = await fetch(fetchURL.href, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to fetch inventory data');
//       }
  
//       const data = await response.json();
//       return data || [];
//     },
//     {
//       enabled: !!selectedSet && !!userId,
//       onSuccess: (data) => setInventoryData(data || []),
//     }
//   );
  
  

  const [SetInvrowCount, SetInvsetRowCount] = useState(0)
  // const {SetInvdata, SetInvfetchNextPage, SetInvisError, SetInvisFetching, SetInvisLoading, SetInvrefetch} =
  //   useInfiniteQuery<SetsApiResponse>({
  //     queryKey: [
  //       'table-data',
  //       SetInvcolumnFilters, //refetch when columnFilters changes
  //       SetInvglobalFilter, //refetch when globalFilter changes
  //       SetInvpagination.pageIndex, //refetch when pagination.pageIndex changes
  //       SetInvpagination.pageSize, //refetch when pagination.pageSize changes
  //       SetInvsorting, //refetch when sorting changes
  //     ],
  //     queryFn: async ({pageParam = 0}) => {
  //       const fetchURL = new URL(
  //         '/sets/',
  //         process.env.NODE_ENV === 'production'
  //           ? `${process.env.REACT_APP_API_URL}`
  //           : 'http://localhost:3000'
  //       )
  //       fetchURL.searchParams.set('page', `${pageParam + 1}`) // +1 because pageParam starts from 0 for the first page
  //       fetchURL.searchParams.set('size', `${pagination.pageSize}`)
  //       fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []))
  //       fetchURL.searchParams.set('search', globalFilter ?? '')
  //       fetchURL.searchParams.set('sort', JSON.stringify(sorting ?? []))

  //       const response = await fetch(fetchURL.href)
  //       const json = (await response.json()) as SetsApiResponse
  //       console.log('Fetched Data:', json)
  //       return json
  //     },
  //     getNextPageParam: (_lastGroup, groups) => groups.length,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   })

  // const [savingRowId, setSavingRowId] = useState<number | null>(null);
  // const [inventoryData, setInventoryData] = useState<UserSetInv[]>([]);
  // const SetInvflatData = useMemo(() => inventoryData ?? [], [inventoryData]);
  // const SetInvtotalDBRowCount = SetInvflatData.length;
  // const SetInvtotalFetched = SetInvflatData.length;

  // const UserSetInvCatalogTable = useMaterialReactTable({
  //   columns: inventoryColumns,
  //   data: inventoryData || [],
  //   enableEditing: true,
  //   createDisplayMode: 'row', // Set row mode for creation
  //   positionCreatingRow: 'top', // Position the new row at the top
  //   enableGlobalFilter: true,
  //   editDisplayMode: 'row',
  //   columnFilterDisplayMode: 'subheader',
  //   enableClickToCopy: false,
  //   enableRowSelection: false,
  //   enableRowVirtualization: true,
  //   enableColumnOrdering: true,
  //   enableGrouping: true,
  //   enableColumnPinning: true,
  //   enableColumnResizing: true,
  //   enableRowPinning: false,
  //   enableRowActions: true,
  //   rowVirtualizerInstanceRef: SetInvrowVirtualizerInstanceRef, // Get access to the virtualizer instance
  //   rowVirtualizerOptions: { overscan: 10 },
  //   initialState: {
  //     showColumnFilters: true,
  //     columnOrder: ['set_num', 'quantity', 'price_paid', 'taxes_fees', 'date_acquired', 'vendor','collection', 'location'],
  //   },
  //   renderEmptyRowsFallback: () => (
  //     <Typography variant="body2" style={{ padding: '16px', textAlign: 'center' }}>
  //       No inventory found for this set.
  //     </Typography>
  //   ),
  //   onCreatingRowSave: async ({ row, table }) => {
  //     setSavingRowId(row.original.id ?? null);
  //     try {
  //       const updatedValues = row.getAllCells().reduce((acc, cell) => {
  //         acc[cell.column.id] = cell.getValue();
  //         return acc;
  //       }, {} as Record<string, any>);
    
  //       const rowData: UserSetInv = {
  //         user_id: userId ?? 0,
  //         set_num: selectedSet?.set_num!,
  //         quantity: Number(updatedValues['quantity'] ?? 1),
  //         price_paid: Number(updatedValues['price_paid'] ?? 0),
  //         taxes_fees: Number(updatedValues['taxes_fees'] ?? 0),
  //         date_acquired: updatedValues['date_acquired'] || new Date().toISOString(), // Default to today
  //         vendor: updatedValues['vendor'] ?? '',
  //         collection: updatedValues['collection'] ?? '',
  //         location: updatedValues['location'] ?? '',
  //         condition: updatedValues['condition'] ?? '',
  //       };
    
  //       await createInventoryRow(rowData, selectedSet, userId ?? 0);
  //       refetchInventory();
  //       table.setCreatingRow(null);
  //     } catch (error) {
  //       console.error('Error creating inventory row:', error);
  //     } finally {
  //       setSavingRowId(null);
  //     }
  //   },
  //   onEditingRowSave: async ({ row, table }) => {
  //     setSavingRowId(row.original.id ?? 0); // Set the row ID being saved
  //     try {
  //       // Prepare updated row data
  //       const updatedValues = row.getAllCells().reduce((acc, cell) => {
  //         acc[cell.column.id] = cell.getValue();
  //         return acc;
  //       }, {} as Record<string, any>);
    
  //       const updatedRowData: UserSetInv = {
  //         ...row.original,
  //         quantity: Number(updatedValues.quantity ?? 1),
  //         price_paid: Number(updatedValues.price_paid ?? 0),
  //         date_acquired: updatedValues.date_acquired || null,
  //         collection: updatedValues.collection || '',
  //         location: updatedValues.location || '',
  //         condition: updatedValues.condition || '',
  //       };
    
  //       await updateInventoryRow(updatedRowData); // Call the update API
  //       refetchInventory(); // Refetch inventory data
  //       table.setEditingRow(null); // Exit editing mode
  //     } catch (error) {
  //       console.error('Error updating row:', error);
  //     } finally {
  //       setSavingRowId(null); // Reset savingRowId
  //     }
  //   },
  //   displayColumnDefOptions: {
  //     'mrt-row-actions': {
  //       muiTableHeadCellProps: {
  //         align: 'left', // Alignment for the header
  //         style: { width: '100px' }, // Header width
  //       },
  //       muiTableBodyCellProps: {
  //         style: { width: '100px', textAlign: 'left' }, // Body cell width
  //       },
  //     },
  //     'mrt-row-numbers': {
  //       enableColumnOrdering: true, // Turn on some features that are usually off
  //       enableResizing: true,
  //       muiTableHeadCellProps: {
  //         sx: {
  //           fontSize: '1.2rem',
  //         },
  //       },
  //     },
  //   },
  //   renderDetailPanel: ({ row }) => (
  //     <Box
  //       sx={{
  //         display: 'grid',
  //         margin: 'auto',
  //         gridTemplateColumns: '1fr 1fr',
  //         width: '100%',
  //       }}
  //     />
  //   ),
  //   manualFiltering: true,
  //   manualPagination: true,
  //   manualSorting: true,
  //   muiTableContainerProps: {
  //     ref: SetInvtableContainerRef, // Get access to the table container element
  //     sx: { maxHeight: '400px' }, // Give the table a max height
  //   },
  //   muiToolbarAlertBannerProps: isError
  //     ? {
  //         color: 'error',
  //         children: 'Error loading data.',
  //       }
  //     : undefined,
  //     renderTopToolbarCustomActions: ({ table }) => (
  //       <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
  //         <Tooltip arrow title="Refresh Data">
  //           <IconButton onClick={() => refetchInventory()}>
  //             <RefreshIcon />
  //           </IconButton>
  //         </Tooltip>
  //         <Button
  //           variant="contained"
  //           onClick={() => {
  //             // Just set it to true
  //             table.setCreatingRow(true);
  //           }}
  //         >
  //           Add Inventory
  //         </Button>
  //       </div>
  //     ),
  //   state: {
  //     columnFilters: SetInvcolumnFilters,
  //     globalFilter: SetInvglobalFilter,
  //     pagination: SetInvpagination,
  //     sorting: SetInvsorting,
  //     isLoading: isError,
  //   },
  //   onColumnFiltersChange: SetInvsetColumnFilters,
  //   onGlobalFilterChange: SetInvsetGlobalFilter,
  //   onPaginationChange: SetInvsetPagination,
  //   onSortingChange: SetInvsetSorting,
  //   renderRowActions: ({ row, table }) => (
  //     <Box sx={{ display: 'flex', gap: '0.5rem' }}>
  //       {savingRowId === row.original.id || savingRowId === 0 ? (
  //         <Tooltip title="Saving...">
  //           <IconButton disabled>
  //             <CircularProgress size={24} />
  //           </IconButton>
  //         </Tooltip>
  //       ) : (
  //         <>
  //           <Tooltip title="Edit">
  //             <IconButton onClick={() => table.setEditingRow(row)}>
  //               <EditIcon />
  //             </IconButton>
  //           </Tooltip>
  //           <Tooltip title="Delete">
  //             <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
  //               <DeleteIcon />
  //             </IconButton>
  //           </Tooltip>
  //         </>
  //       )}
  //     </Box>
  //   ),
  // });

  // //DELETE action
  // const openDeleteConfirmModal = (row: MRT_Row<UserSetInv>) => {
  //   if (window.confirm('Are you sure you want to delete this row from inventory?')) {
  //     deleteInventoryRow(row.original.id!)
  //       .then(() => {
  //         // Safely filter out the deleted row
  //         const updatedData = (inventoryData || []).filter(
  //           (item) => item.id && item.id !== row.original.id
  //         );
  
  //         setInventoryData(updatedData);
  
  //         // If the data is empty, refetch to ensure sync with backend
  //         if (updatedData.length === 0) {
  //           refetchInventory();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error deleting inventory row:', error);
  //       });
  //   }
  // };

  return (
    <div className={`card ${className} w-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 w-100'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Catalog - Sets</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
          { (data?.pages?.[0]?.meta?.totalRowCount ?? 0).toLocaleString() } Total Set(s) in Selection
          </span>
        </h3>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 w-100'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <MaterialReactTable
            table={SetsCatalogTable}
          />
{/* Modal */}
<div className="modal bg-body fade" tabIndex={-1} id="kt_modal_2">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content shadow-none">
      <div className="modal-header" style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
      <h5 className="modal-title">
        {selectedSet ? (
            <>
              <span style={{ marginRight: '10px' }}>
                {`${selectedSet.set_num}: ${selectedSet.name}`}
              </span>
                <img 
                    src={selectedSet.img_url} 
                    alt={`${selectedSet.name} thumbnail`} 
                    style={{ height: '50px', marginRight: '10px' }} // You can adjust the style as needed
                />
            </>
        ) : (
            "Set Details"
        )}
    </h5><span style={{ marginRight: '30px' }}>
        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          onClick={() => {
            // Navigate back in history when the modal is closed
            window.history.back();
          }}
        >Close</button>
          {/* <i className="ki-duotone ki-cross fs-1">
          <KTIcon iconName='cross' className='fs-1' />
          </i> */}
        </div></span>
      </div>
      <div className="modal-body">
  <div className="row g-5 align-items-stretch">
    {/* Set Overview Column */}
    <div className="col-lg-3 d-flex flex-column">
      <div className="card card-custom card-stretch shadow mb-5 d-flex flex-column">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Set Overview</h3>
          {selectedSet && getRetirementImage() && (
            <img 
              src={getRetirementImage() || ''}  // Ensure src is always a string
              alt="Retirement status" 
              style={{ height: '30px', marginLeft: 'auto' }}  // Adjust the height as needed
            />
          )}
        </div>
        <div className="card-body py-2">
          <table className="table table-row-dashed table-row-gray-300 gy-2">
            <tbody>
              {/* Set overview table content */}
              <tr>
                <td>Set Number</td>
                <td>{selectedSet?.set_num ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedSet?.name ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Theme</td>
                <td>{selectedSet?.theme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Subtheme</td>
                <td>{selectedSet?.subtheme ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Pieces</td>
                <td>{selectedSet?.num_parts != null ? selectedSet.num_parts.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Minifigures</td>
                <td>{selectedSet?.minifig_count != null ? selectedSet.minifig_count.toLocaleString() : '0'}</td>
              </tr>
              <tr>
                <td>Release Date</td>
                <td>{selectedSet?.date_released ? selectedSet.date_released.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Retirement Date</td>
                <td>{selectedSet?.date_retired ? selectedSet.date_retired.toLocaleDateString() : "N/A"}</td>
              </tr>
              <tr>
                <td>Dimensions (I)</td>
                <td>
                  {selectedSet && selectedSet.dim_length_imperial != null && selectedSet.dim_width_imperial != null && selectedSet.dim_height_imperial != null
                    ? `${selectedSet.dim_length_imperial} x ${selectedSet.dim_width_imperial} x ${selectedSet.dim_height_imperial} in`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (I)</td>
                <td>
                  {selectedSet?.weight_imperial != null
                    ? `${Math.floor(selectedSet.weight_imperial)} lb ${Math.round((selectedSet.weight_imperial % 1) * 16)} oz`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Dimensions (M)</td>
                <td>
                  {selectedSet?.dim_length_metric != null && selectedSet.dim_width_metric != null && selectedSet.dim_height_metric != null
                    ? `${selectedSet.dim_length_metric} x ${selectedSet.dim_width_metric} x ${selectedSet.dim_height_metric} cm`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Weight (M)</td>
                <td>
                  {selectedSet?.weight_metric != null
                    ? `${selectedSet.weight_metric} g`
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>MSRP (USD)</td>
                <td>{selectedSet?.rrp_usd != null ? `$${selectedSet.rrp_usd}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (GBP)</td>
                <td>{selectedSet?.rrp_gbp != null ? `£${selectedSet.rrp_gbp}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (CAD)</td>
                <td>{selectedSet?.rrp_cad != null ? `C$${selectedSet.rrp_cad}` : "N/A"}</td>
              </tr>
              <tr>
                <td>MSRP (EUR)</td>
                <td>{selectedSet?.rrp_eur != null ? `€${selectedSet.rrp_eur}` : "N/A"}</td>
              </tr>
              <tr>
                <td>UPC Barcode</td>
                <td>{selectedSet?.upc_barcode ?? "N/A"}</td>
              </tr>
              <tr>
                <td>EAN Barcode</td>
                <td>{selectedSet?.ean_barcode ?? "N/A"}</td>
              </tr>
              {/* <tr>
                <td>Item Number</td>
                <td>{selectedSet?.item_number ?? "N/A"}</td>
              </tr>
              <tr>
                <td>BOID</td>
                <td>{selectedSet?.boid ?? "N/A"}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>

    {/* Minifigures and other sections */}
    <div className="col-lg-9 d-flex flex-column">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-body">
              {selectedSet && galleryImages.length > 0 ? (
                <ImageGallery 
                  items={galleryImages} 
                  showNav={false}
                  thumbnailPosition="right"
                />
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Metrics - Coming soon!</h3>
            </div>
          </div>
        </div>
      </div>
                
      <div className="row g-5">
        <div className="col-lg-12">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-header">
              <h3 className="card-title">Minifigures</h3>
            </div>
            <div className="card-body" style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {minifigures.length > 0 ? (
                <table className="table table-row-dashed table-row-gray-300 gy-2">
                  <thead>
                    <tr>
                      <th>Thumbnail</th>
                      <th>Quantity</th>
                      <th>Minifigure</th>
                      <th>Bricklink #</th>
                      <th>Name</th>
                      <th>Pieces</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minifigures.map((minifig) => (
                      <tr key={minifig.fig_num}>
                        <td><img src={minifig.img_url} alt={minifig.name} height={50} /></td>
                        <td>{minifig.quantity}</td>
                        <td>{minifig.fig_num}</td>
                        <td>{minifig.bricklink_num}</td>
                        <td>{minifig.name}</td>
                        <td>{minifig.num_parts}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No minifigures found for this set.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-12 d-flex flex-column">
        <div className="card card-custom card-stretch shadow mb-5">
          <div className="card-header">
            <h3 className="card-title">Parts</h3>
          </div>
          <div className="card-body">Parts coming soon!</div>
        </div>
      </div> */}
    </div>
    <div className="row g-5">
        <div className="col-lg-12">
          <div className="card card-custom card-stretch shadow mb-5">
            <div className="card-body" style={{ maxHeight: '620px' }}>
                <InventoryManagementTable 
                userSelectedSet={selectedSet?.set_num}
                />
            </div>
          </div>
        </div>
    </div>
  {/* </div>
</div> */}


  {/* 
      <div className="card card-custom card-stretch-33 shadow mb-5">
          <div className="card-header">
              <h3 className="card-title">Height 33.333%</h3>
          </div>
          <div className="card-body">
              Lorem Ipsum is simply dummy text
          </div>
          <div className="card-footer">
              Footer
          </div>
      </div>

      <div className="card card-custom card-stretch-33 shadow mb-5">
          <div className="card-header">
              <h3 className="card-title">Height 33.333%</h3>
          </div>
          <div className="card-body">
              Lorem Ipsum is simply dummy text
          </div>
          <div className="card-footer">
              Footer
          </div>
      </div>
  </div> */}
{/* </div> */}

{/* <div className='accordion' id='kt_accordion_1'>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_1'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_1'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_1'
    >
      Price History Chart
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_1'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_1'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
      <AccordionManager selectedSet={selectedSet} />
    </div>
  </div>
</div>
<div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
          <button
            className='accordion-button fs-4 fw-bold collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_2'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_2'
          >
            Sales Volume Chart
          </button>
        </h2> */}
        {/* <div
          id='kt_accordion_1_body_2'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_2'
          data-bs-parent='#kt_accordion_1'
        >
          <div className='accordion-body'>
          <div className="row g-5">
        <div className="col-lg-4">
            <div className="card card-custom card-stretch shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Pictures Lightbox</h3>
                </div>
                <div className="card-body">
                  <ImageGallery items={galleryImages} />
                </div>
                <div className="card-footer">   
                </div>
            </div>
        </div>

        <div className="col-lg-4">
            <div className="card card-custom card-stretch-50 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Minifigures Table</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>

            <div className="card card-custom card-stretch-50 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Inventory Management</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>
        </div>

        <div className="col-lg-4">
            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                Coming soon!
                </div>
                <div className="card-footer">
                  
                </div>
            </div>

            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    Footer
                </div>
            </div>

            <div className="card card-custom card-stretch-33 shadow mb-5">
                <div className="card-header">
                    <h3 className="card-title">Height 33.333%</h3>
                </div>
                <div className="card-body">
                    Coming soon!
                </div>
                <div className="card-footer">
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </div> */}
{/* </div>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_3'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_3'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_3'
    >
      Coming soon!
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_3'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_3'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
    </div>
  </div> */}
            {/* </div> */}
            {/* <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      </div>
      </div>
      </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const queryClient = new QueryClient()

const CatalogSetsTable: React.FC<CatalogSetsTableProps> = () => (
  <QueryClientProvider client={queryClient}>
    <SetsCatalog /> {/* Pass className as a prop */}
  </QueryClientProvider>
)

export {CatalogSetsTable}
