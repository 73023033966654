import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useSortBy, useFilters, usePagination } from 'react-table';
import { Column } from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { ColumnFilter } from './ColumnFilter';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { TableState } from 'react-table';
import axios from 'axios';
import {
  setAuth,
  removeAuth,
  setupAxios,
} from '../../../../app/modules/auth/core/AuthHelpers'
import callApi from '../../../../app/helpers/callApi'

type Props = {
  className: string;
};

type RowData = {
  set_number: string;
  set_name: string;
  item_number: string;
  pieces: number;
  upc_barcode: string;
};

type ExtendedTableState = TableState<RowData> & {
  globalFilter: any;
};

const TablesWidget10: React.FC<Props> = ({ className }) => {
  
  const [tableData, setTableData] = useState([]);


  const columns: Column<RowData>[] = useMemo(
    () => [
      {
        Header: '',
        id: 'selection',
        accessor: () => '',
        Cell: () => (
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
          </div>
        ),
        disableGlobalFilter: true,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Set Number',
        accessor: 'set_number',
        Cell: ({ row }) => (
          <div>
            <span>{row.values.set_number}</span>
          </div>
        ),
        Filter: ColumnFilter,
        disableGlobalFilter: false,
      },
      {
        Header: 'Set Name',
        accessor: 'set_name',
        Cell: ({ row }) => (
          <div>
            <span>{row.values.set_name}</span>
          </div>
        ),
        Filter: ColumnFilter,
      },
      
      {
        Header: 'Item Number',
        accessor: 'item_number',
        Cell: ({ row }) => (
          <div>
            <span>{row.values.item_name}</span>
          </div>
        ),
        Filter: ColumnFilter,
      },
      {
        Header: 'UPC',
        accessor: 'upc_barcode',
        Cell: ({ row }) => (
          <div>
            <span>{row.values.upc_barcode}</span>
          </div>
        ),
        Filter: ColumnFilter,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: () => '',
        Cell: () => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <KTIcon iconName='switch' className='fs-3' />
            </a>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </a>
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTIcon iconName='trash' className='fs-3' />
            </a>
          </div>
        ),
        disableGlobalFilter: false,
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    []


  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data: tableData },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const Pagination = () => {
    return (
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    );
  };  
  
  const extendedState = state as ExtendedTableState;
  const { globalFilter } = extendedState;
  
  // useEffect(() => {
  //   const auth = getAuth()
  //   const accessToken = auth?.accessToken // Replace this with the actual access token

  //   if (!accessToken) {
  //     console.error('Access token is missing')
  //     return
  //   }

  //   const fetchData = async () => {
  //     try {
  //       const setsOwnedResponse = await callApi.get(`/setsowned/`)
  //       console.log('setsOwnedResponse', setsOwnedResponse)
  //       setTableData(setsOwnedResponse.data)
  //     } catch (error) {
  //       console.error('Error fetching user data and sets owned:', error)
  //     }
  //   }

  //   fetchData()
  // }, [])

  return (
    <div className={`card ${className} w-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 w-100'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Inventory Management - Sets</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>VAR Total Sets in Inventory</span>
        </h3>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 w-100'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            {...getTableProps()}
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 w-100'
          >
            {/* begin::Table head */}
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody {...getTableBodyProps()}>
            {rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
         {/* end::Table container */}
         </div>
         <Pagination />
      {/* end::Body */}
    </div>
  );
};

export { TablesWidget10 };
      
