import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface WarningPromptProps {
  timeLeft: number;
  onStayActive: () => void;
  onLogout: () => void;
  onClose: () => void;
}

const WarningPrompt: React.FC<WarningPromptProps> = ({
  timeLeft,
  onStayActive,
  onLogout,
  onClose,
}) => {
  React.useEffect(() => {
    if (timeLeft <= 0) {
      onClose();
      onLogout();
    }
  }, [timeLeft, onLogout, onClose]);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session Expiring</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your session is about to expire due to inactivity.</p>
        <p>Time left: {timeLeft} seconds</p>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            onStayActive();
            onClose(); // Close the modal immediately
          }}
        >
          Stay Logged In
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onLogout(); // Call the logout function
            window.location.href = '/login'; // Redirect to the login page
          }}
        >
          Log Out
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default WarningPrompt;
