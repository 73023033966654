import {
  FC,
  useState,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { getUserByToken } from './_requests';
import { WithChildren } from '../../../../_metronic/helpers';
import { signOut } from 'aws-amplify/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: undefined,
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  // ✅ Initialize auth state by reading the session cookie
  const getInitialAuth = (): AuthModel | undefined => {
    try {
      const cookies = document.cookie.split('; ');
      const accessTokenCookie = cookies.find((cookie) => cookie.startsWith('accessToken='));
      if (accessTokenCookie) {
        const accessToken = accessTokenCookie.split('=')[1];
        return { accessToken }; // Adjust according to your AuthModel
      }
    } catch (error) {
      console.error('Error reading cookies:', error);
    }
    return undefined;
  };

  // ✅ Use the new `getInitialAuth` function to set the initial state
  const [auth, setAuth] = useState<AuthModel | undefined>(getInitialAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = async () => {
    try {
      // Step 1: Sign out from Cognito
      await signOut();

      // Step 2: Call backend to clear session
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, { withCredentials: true });

      // Step 3: Clear local auth state
      saveAuth(undefined);
      setCurrentUser(undefined);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await getUserByToken();
        if (data) {
          setCurrentUser(data.user);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => {
        didRequest.current = true;
      };
    };

    if (!auth) {
      logout();
      setShowSplashScreen(false);
    } else {
      fetchUser();
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
