import { PageTitle } from '../../_metronic/layout/core';

export function AffiliateDisclosures() {
  return (
    <>
      {/* Page title */}
      <PageTitle breadcrumbs={[]}>AffiliateDisclosures</PageTitle>

      {/* Single large content card */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-12">
          <div className="card h-100">
            <div className="card-body">
              <h3 className="card-title">AffiliateDisclosures</h3>
              <p>Roadmap coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
