import React, {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'

const TogglePurchaseDrawer: FC = () => {


  return (
    <>

        <button
          className='engage-purchase-toggle btn btn-flex h-35px bg-body btn-active-color-primary fw-bold'
          id='kt_purchase_toggle'
          title='Submit Feedback'
          data-bs-toggle='tooltip'
          data-bs-placement='left'
          data-bs-dismiss='click'
          data-bs-trigger='hover'
        >
          Become a Pro
        </button>
    </>
  )
}

export {TogglePurchaseDrawer}
