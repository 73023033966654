import {FC} from 'react'
import {ToggleHelpDrawer} from './help-drawer/ToggleHelpDrawer'
import {HelpDrawer} from './help-drawer/HelpDrawer'
import {PurchaseDrawer} from './purchase/PurchaseDrawer'
import {TogglePurchaseDrawer} from './purchase/TogglePurchaseDrawer'
import { useAuth } from '../../../app/modules/auth'

const RightToolbar: FC = () => {
  const {currentUser} = useAuth()
  const permissions = currentUser?.permissions || []
  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        <ToggleHelpDrawer />
        {permissions.includes('use_sales_ledger') && (
        <TogglePurchaseDrawer /> )}
      </div>
       
      <HelpDrawer />
      <PurchaseDrawer />
    </>
  )
}

export {RightToolbar}
