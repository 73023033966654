/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
}

const EngageWidget10 = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='mb-10'>
        <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
          <span className='me-2'>
            Welcome to StudFinder!
            <br />
            <span className='position-relative d-inline-block text-danger'>
              {/* <Link
                to='/crafted/pages/profile/overview'
                className='text-danger
              opacity-75-hover'
              >
                Pro Plan
              </Link> */}

              <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
            </span>
          </span>
          We're in development!
        </div>

        <div className="text-center">
  <p className="mt-4">
  Welcome to our application! We're thrilled to share this exciting journey with you as we continue developing innovative tools designed to enhance the experience of LEGO investors, enthusiasts, and collectors like you. Our mission is to equip our community with seamless solutions to build, manage, and monitor inventories and collections with ease and efficiency.
  </p>
  <p className="mt-3">
  As we're still in the development phase, you might encounter some bugs or unexpected issues along the way. Rest assured, we're actively working on improvements to ensure our platform meets your needs and expectations. Your feedback is invaluable to us during this process—it helps us refine our features, address challenges, and deliver a product that truly serves the LEGO community.
  </p>
  <p className="mt-3">
  If you notice anything that needs fixing, have suggestions for improvement, or simply want to share your thoughts, please don’t hesitate to reach out. Your patience and support mean the world to us, and we’re grateful to have you as part of this journey. Together, we can build a platform that empowers LEGO fans everywhere. Thank you for being here!
  </p>
</div>
      </div>
      <img
        className='mx-auto h-150px h-lg-200px  theme-light-show'
        src={toAbsoluteUrl('/media/studfinder/logo.svg')}
        alt=''
      />
      <img
        className='mx-auto h-150px h-lg-200px  theme-dark-show'
        src={toAbsoluteUrl('/media/studfinder/logo.svg')}
        alt=''
      />
    </div>
  </div>
)
export {EngageWidget10}
