/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useNavigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import { fetchAuthSession } from 'aws-amplify/auth'
import App from '../App'
import { getUserByToken } from '../modules/auth/core/_requests'
import {PurchaseDrawer} from '../../_metronic/partials/layout/purchase/PurchaseDrawer'
import ReactGA from 'react-ga4'
// import Return from '../components/Return'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, setCurrentUser} = useAuth()

  // Check for authentication on initial load
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {data} = await getUserByToken()
        if (data.user) {
          setCurrentUser(data.user)
        }
      } catch (error) {
        console.error('User refresh error:', error)
      }
    }

    // Only fetch the user if there's no current user in context
    if (!currentUser) {
      fetchUser()
    }
  }, [currentUser, setCurrentUser])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              {/* Private routes for authenticated users */}
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              {/* Public routes for unauthenticated users */}
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          {/* Your New Stripe Routes */}
          <Route path='/purchase' element={<PurchaseDrawer />} />
          <Route path='/return' element={<PurchaseDrawer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

// Location tracker for Google Analytics
const LocationTracker: FC = () => {
  const location = useLocation()

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.send({hitType: 'pageview', page: location.pathname + location.search})
    }
  }, [location])

  return null
}

export default AppRoutes