import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth'

const {REACT_APP_API_URL} = process.env
let headers = {}

const callApi = axios.create({
  baseURL: REACT_APP_API_URL,
  headers,
})

callApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    if (idToken) {
      // Ensure headers are correctly set using AxiosHeaders
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      config.headers.set('Authorization', `Bearer ${idToken.toString()}`);
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

callApi.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      console.log("Enter here", response)
      resolve(response)
    }),
  async (error) => {
    const originalRequest = error.config;

    if(error) {
      console.log({
        error,
        originalRequest
      })
    }
  }
);


export default callApi