import { PageTitle } from '../../_metronic/layout/core';

export function PrivacyPolicy() {
  return (
    <>
      {/* Page title */}
      <PageTitle breadcrumbs={[]}>Privacy Policy</PageTitle>

      {/* Single large content card */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div className="col-12">
          <div className="card h-100">
            <div className="card-body">
              <h3 className="card-title">Privacy Policy</h3>
              <p>
                <strong>Effective Date:</strong> December 27, 2024
              </p>

              <p>
                At StudFinder (“we,” “us,” or “our”), we value and respect your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our web app (the “App”) and its related services. By accessing or using the App, you agree to the practices described in this Privacy Policy. If you do not agree to these practices, please do not use the App.
              </p>

              <h4>Information We Collect</h4>
              <ul>
                <li>
                  <strong>Personal Information:</strong> This may include your name, email address, contact information, and other details you provide when registering or using the App.
                </li>
                <li>
                  <strong>Account Information:</strong> If you create an account, we may collect your account details, including username and password.
                </li>
                <li>
                  <strong>Device Information:</strong> We may collect information about your device, including device type, operating system, browser type, and IP address.
                </li>
                <li>
                  <strong>Usage Data:</strong> We may track how you interact with the App, including features you use, actions taken, and content viewed.
                </li>
                <li>
                  <strong>Payment Information:</strong> If applicable, we may collect payment data (such as billing address and payment method) when you make transactions through the App.
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and similar technologies to track usage patterns, remember preferences, and personalize your experience. You can manage cookie settings through your device or browser settings.
                </li>
              </ul>

              <h4>How We Use Your Information</h4>
              <ul>
                <li>To provide and improve the App and its features.</li>
                <li>To communicate with you, including sending updates and promotions.</li>
                <li>To process payments for in-app purchases or services.</li>
                <li>To personalize your experience based on preferences and activity.</li>
                <li>To analyze usage patterns and improve the functionality of the App.</li>
                <li>To comply with legal requirements and processes.</li>
              </ul>

              <h4>How We Share Your Information</h4>
              <ul>
                <li>
                  <strong>Third-Party Service Providers:</strong> We may share your information with third-party providers who assist in operating the App, processing payments, and performing other services.
                </li>
                <li>
                  <strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, such as responding to subpoenas or court orders.
                </li>
                <li>
                  <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.
                </li>
                <li>
                  <strong>Analytics and Advertising:</strong> Non-personal data may be shared with third-party analytics providers and advertisers.
                </li>
              </ul>

              <h4>Data Security</h4>
              <p>
                We implement reasonable security measures to protect personal data. However, no method of transmission or storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
              </p>

              <h4>Data Retention</h4>
              <p>
                We retain your information as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. You can request deletion of your account at any time, and we will process the request as required by applicable laws.
              </p>

              <h4>Your Choices and Rights</h4>
              <ul>
                <li>Access and update your personal information through account settings.</li>
                <li>Opt-out of receiving promotional emails or notifications.</li>
                <li>Request data deletion by contacting us at <a href="mailto:privacy@studfinder.app">privacy@studfinder.app</a>.</li>
                <li>Manage your cookie preferences through browser settings.</li>
              </ul>

              <h4>Children’s Privacy</h4>
              <p>
                The App is not intended for children under the age of 13. We do not knowingly collect personal information from children. If we learn we have, we will take steps to delete it.
              </p>

              <h4>International Transfers</h4>
              <p>
                Your information may be transferred to countries outside your jurisdiction. By using the App, you consent to these transfers, including to countries that may not have equivalent data protection laws.
              </p>

              <h4>Changes to This Privacy Policy</h4>
              <p>
                We reserve the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting. Review this Privacy Policy periodically to stay informed.
              </p>

              <h4>Contact Us</h4>
              <p>
                If you have questions or concerns about this Privacy Policy, contact us at:
              </p>
              <address>
                <a href="mailto:privacy@studfinder.app">privacy@studfinder.app</a>
              </address>

              <p>
                By using the App, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
