import axios from 'axios';

// Enable cookies for cross-site requests
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set your base URL
  withCredentials: true, // Ensure cookies are included in requests
  headers: {
    'Accept': '*/*', // ✅ Match the fetch request's Accept header
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, success: boolean = true) => {
  failedQueue.forEach((prom) => {
    if (success) {
      prom.resolve();
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Response interceptor for handling responses and errors
// Response interceptor for handling responses and errors
axiosInstance.interceptors.response.use(
  (response) => response, // ✅ Pass successful responses through
  async (error) => {
    const originalRequest = error.config;

    // ✅ Handle 401 errors and trigger a refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          // ✅ Call the backend to refresh tokens
          const refreshResponse = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {}, { withCredentials: true });

          if (refreshResponse.status === 200) {
            processQueue(null, true);

            // ✅ Retry the original request with the new token
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          processQueue(refreshError, false);

          // ❌ If the refresh fails, clear the queue and redirect to login
          failedQueue = [];
          window.location.href = '/auth';
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      // If another request is already refreshing, queue this request
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }

    // Pass other errors through
    return Promise.reject(error);
  }
);

export default axiosInstance;
