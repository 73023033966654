import { useAuth } from './core/Auth'; // Your custom auth hook
import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds
const WARNING_TIME = 30 * 1000; // 30 seconds before auto-logout

export function useAutoLogout() {
  const { logout, currentUser } = useAuth();
  const location = useLocation();
  const [showWarning, setShowWarning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(WARNING_TIME / 1000);
  
  // Using number type for browser environments
  const warningTimer = useRef<number | null>(null);
  const countdownInterval = useRef<number | null>(null);

  // Start the countdown when the warning modal is displayed
  const startCountdown = useCallback(() => {
    let countdown = WARNING_TIME / 1000;
    setTimeLeft(countdown);

    countdownInterval.current = window.setInterval(() => {
      countdown -= 1;
      setTimeLeft(countdown);

      if (countdown <= 0) {
        clearInterval(countdownInterval.current!);
        setShowWarning(false);
        logout();
      }
    }, 1000);
  }, [logout]);

  // Reset timers on user activity or when "Stay Logged In" is clicked
  const resetTimer = useCallback(() => {
    if (currentUser && location.pathname !== '/auth/login') {
      // Clear existing timers
      if (warningTimer.current) {
        clearTimeout(warningTimer.current);
      }
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }

      // Reset state
      setTimeLeft(WARNING_TIME / 1000);
      setShowWarning(false);

      // Set a new warning timer
      warningTimer.current = window.setTimeout(() => {
        setShowWarning(true);
        startCountdown();
      }, AUTO_LOGOUT_TIME - WARNING_TIME);
    }
  }, [currentUser, logout, location.pathname, startCountdown]);

  // Cleanup when user logs out or navigates to login page
  useEffect(() => {
    if (!currentUser || location.pathname === '/auth/login') {
      if (warningTimer.current) {
        clearTimeout(warningTimer.current);
      }
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
      setShowWarning(false);
    }
  }, [currentUser, location.pathname]);

  // Initialize timers and add event listeners for user activity
  useEffect(() => {
    if (currentUser) {
      resetTimer();

      // List of events that indicate user activity
      const events = ['mousemove', 'mousedown', 'keypress', 'touchmove'];

      // Reset timer on any of these events
      events.forEach((event) => window.addEventListener(event, resetTimer));

      // Cleanup on unmount
      return () => {
        events.forEach((event) => window.removeEventListener(event, resetTimer));
        if (warningTimer.current) {
          clearTimeout(warningTimer.current);
        }
        if (countdownInterval.current) {
          clearInterval(countdownInterval.current);
        }
      };
    }
  }, [currentUser, resetTimer]);

  return { resetTimer, showWarning, timeLeft };
}
