import {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

import { useAutoLogout} from '../app/modules/auth/AutoLogout'
import {GoogleOAuthProvider} from '@react-oauth/google'

import { Amplify } from 'aws-amplify';

import React from 'react'
import WarningPrompt from './modules/auth/components/WarningPrompt';
import { useAuth } from './modules/auth'
import axiosInstance from './modules/auth/core/axiosInstance'

const Return = () => {
  const [status, setStatus] = React.useState<string | null>(null)
  const [customerEmail, setCustomerEmail] = React.useState<string>('')

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
  
    axiosInstance
      .get(`/session-status?session_id=${sessionId}`)
      .then((res) => {
        setStatus(res.data.status);
        setCustomerEmail(res.data.customer_email);
      })
      .catch((error) => {
        console.error('Error fetching session status:', error);
      });
  }, []);

  if (status === 'open') {
    return <p>Your session is still active.</p>
  }

  if (status === 'complete') {
    return (
      <section id='success'>
        <p>
          Thank you for your purchase! A confirmation email will be sent to {customerEmail}.
        </p>
      </section>
    )
  }

  return null
}

// export function useAutoLogoutOnBrowserClose() {
//   const { logout } = useAuth();

//   useEffect(() => {
//     const handleBeforeUnload = () => {
//       logout(); // Trigger your logout function
//     };

//     // Add the event listener to detect when the browser is closed
//     window.addEventListener('beforeunload', handleBeforeUnload);

//     // Cleanup the event listener when the component unmounts
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [logout]);
// }

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: `${process.env.REACT_APP_AWS_USER_POOLS_ID}`,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: `${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}`,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: `${process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL}`,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
    }
  }
});


const App = () => {
  const { resetTimer, showWarning, timeLeft } = useAutoLogout();
  const { currentUser } = useAuth();
  // useAutoLogoutOnBrowserClose();
  
  const navigate = useNavigate()
  const location = useLocation()

  // Save the current location in sessionStorage on route change
  useEffect(() => {
    sessionStorage.setItem('lastLocation', location.pathname + location.search)
  }, [location])

  // Restore the last location on initial load
  useEffect(() => {
    const lastLocation = sessionStorage.getItem('lastLocation')
    if (lastLocation && location.pathname === '/') {
      navigate(lastLocation)
    }
  }, [navigate, location.pathname])

  const handleStayActive = () => {
    resetTimer();
  };

  return (

    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
            <GoogleOAuthProvider clientId='203914289973-u7af487fleqqtk4rda72eqvrbkfogi64.apps.googleusercontent.com'>
                <Outlet />
                <MasterInit />
                {showWarning && currentUser && ( // ✅ Ensure the prompt only shows if a user is logged in
              <WarningPrompt
                timeLeft={timeLeft}
                onStayActive={handleStayActive}
                onLogout={() => window.location.href = '/login'}
                onClose={handleStayActive}
              />
            )}
            </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider> 
    </Suspense>
  )
}

export default App;
